.AdminMAinControl .katpanel .secim {
  padding   : 20px 10px;
  margin-top: 10px;
  border    : 1px solid #b1b1b1;
}

.AdminMAinControl .katpanel .secim a i {
  font-size: 13px;
}

.AdminMAinControl .katpanel .secim a {
  margin-left: 5px;
}


.AdminMAinControl tbody td img {
  max-width: 100px;
}


.AdminMAinControl img {
  max-width: 100%;
}

.AdminMAinControl ul,
.AdminMAinControl li {
  list-style: none;
}

.AdminMAinControl table tbody tr td:last-child {
  width: 120px;
}

.AdminMAinControl .bmd-form-group {
  position: relative;
}

.AdminMAinControl .bmd-form-group:not(.has-success):not(.has-danger) [class^='bmd-label'].bmd-label-floating,
.AdminMAinControl .bmd-form-group:not(.has-success):not(.has-danger) [class*=' bmd-label'].bmd-label-floating {
  color: #AAAAAA;
}

.AdminMAinControl .bmd-form-group [class^='bmd-label'],
.AdminMAinControl .bmd-form-group [class*=' bmd-label'] {
  position      : absolute;
  pointer-events: none;
  transition    : 0.3s ease all;
}

.AdminMAinControl .bmd-form-group [class^='bmd-label'].bmd-label-floating,
.AdminMAinControl .bmd-form-group [class*=' bmd-label'].bmd-label-floating {
  will-change: left, top, contents;
  margin     : 0;
  line-height: 1.4;
  font-weight: 400;
}

.AdminMAinControl .bmd-form-group.is-filled .bmd-label-placeholder {
  display: none;
}

.AdminMAinControl .bmd-form-group.bmd-collapse-inline {
  display    : flex;
  align-items: center;
  padding    : 0;
  min-height : 2.1em;
}

.AdminMAinControl .bmd-form-group.bmd-collapse-inline .collapse {
  flex   : 1;
  display: none;
}

.AdminMAinControl .bmd-form-group.bmd-collapse-inline .collapse.show {
  max-width: 1200px;
}

.AdminMAinControl .bmd-form-group.bmd-collapse-inline .collapsing,
.AdminMAinControl .bmd-form-group.bmd-collapse-inline .width:not(.collapse),
.AdminMAinControl .bmd-form-group.bmd-collapse-inline .collapse.show {
  display: block;
}

.AdminMAinControl .bmd-form-group.bmd-collapse-inline .collapsing {
  transition-duration       : 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.AdminMAinControl .bmd-form-group .form-control,
.AdminMAinControl .bmd-form-group label,
.AdminMAinControl .bmd-form-group input::placeholder {
  line-height: 1.1;
}

.AdminMAinControl .bmd-form-group label {
  color: #AAAAAA;
}

.AdminMAinControl .bmd-form-group .radio label,
.AdminMAinControl .bmd-form-group label.radio-inline,
.AdminMAinControl .bmd-form-group .checkbox label,
.AdminMAinControl .bmd-form-group label.checkbox-inline,
.AdminMAinControl .bmd-form-group .switch label {
  line-height: 1.5;
}

.AdminMAinControl .bmd-form-group .checkbox label,
.AdminMAinControl .bmd-form-group .radio label,
.AdminMAinControl .bmd-form-group label {
  font-size: 0.875rem;
}

.AdminMAinControl .bmd-form-group .bmd-label-floating,
.AdminMAinControl .bmd-form-group .bmd-label-placeholder {
  top: 0.6125rem;
}

.AdminMAinControl .bmd-form-group .is-focused .bmd-label-floating,
.AdminMAinControl .bmd-form-group .is-filled .bmd-label-floating {
  top      : -1rem;
  left     : 0;
  font-size: 0.6875rem;
}

.AdminMAinControl .bmd-form-group .bmd-label-static {
  top      : 0.35rem;
  left     : 0;
  font-size: 0.875rem;
}

.AdminMAinControl .bmd-form-group .bmd-help {
  margin-top: 0;
  font-size : 0.75rem;
}

.AdminMAinControl .bmd-form-group .form-control.form-control-success,
.AdminMAinControl .bmd-form-group .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}

.AdminMAinControl .bmd-form-group .form-control.form-control-success,
.AdminMAinControl .bmd-form-group .form-control.form-control-success:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.AdminMAinControl .bmd-form-group .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group .form-control.form-control-warning:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group .form-control.form-control-danger,
.AdminMAinControl .bmd-form-group .form-control.form-control-danger:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger {
  padding-right      : 0;
  background-repeat  : no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.46875rem;
}

.AdminMAinControl .bmd-form-group .form-control.form-control-success:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.AdminMAinControl .bmd-form-group .form-control.form-control-warning:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group .form-control.form-control-danger:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm label,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm input::placeholder {
  line-height: 1.1;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm label {
  color: #AAAAAA;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .radio label,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm label.radio-inline,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .checkbox label,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm label.checkbox-inline,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .switch label {
  line-height: 1.5;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .checkbox label,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .radio label,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm label {
  font-size: 0.875rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .bmd-label-floating,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .bmd-label-placeholder {
  top: 0.175rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .is-focused .bmd-label-floating,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .is-filled .bmd-label-floating {
  top      : -1.25rem;
  left     : 0;
  font-size: 0.6875rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .bmd-label-static {
  top      : 0.1rem;
  left     : 0;
  font-size: 0.875rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .bmd-help {
  margin-top: 0;
  font-size : 0.65625rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.6875rem 0.6875rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  padding-right      : 0;
  background-repeat  : no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.34375rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.6875rem 0.6875rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg label,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg input::placeholder {
  line-height: 1.1;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg label {
  color: #AAAAAA;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .radio label,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg label.radio-inline,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .checkbox label,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg label.checkbox-inline,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .switch label {
  line-height: 1.5;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .checkbox label,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .radio label,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg label {
  font-size: 0.875rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .bmd-label-floating,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .bmd-label-placeholder {
  top: 0.7375rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .is-focused .bmd-label-floating,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .is-filled .bmd-label-floating {
  top      : -1rem;
  left     : 0;
  font-size: 0.6875rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .bmd-label-static {
  top      : 0.35rem;
  left     : 0;
  font-size: 0.875rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .bmd-help {
  margin-top: 0;
  font-size : 0.9375rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 1.1875rem 1.1875rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  padding-right      : 0;
  background-repeat  : no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.59375rem;
}

.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.AdminMAinControl .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.AdminMAinControl .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 1.1875rem 1.1875rem;
}

.AdminMAinControl .form-control,
.AdminMAinControl label,
.AdminMAinControl input::placeholder {
  line-height: 1.1;
}

.AdminMAinControl label {
  color: #AAAAAA;
}

.AdminMAinControl .radio label,
.AdminMAinControl label.radio-inline,
.AdminMAinControl .checkbox label,
.AdminMAinControl label.checkbox-inline,
.AdminMAinControl .switch label {
  line-height: 1.5;
}

.AdminMAinControl .checkbox label,
.AdminMAinControl .radio label,
.AdminMAinControl label {
  font-size: 0.875rem;
}

.AdminMAinControl .bmd-label-floating,
.AdminMAinControl .bmd-label-placeholder {
  top: 0.6125rem;
}

.AdminMAinControl .is-focused .bmd-label-floating,
.AdminMAinControl .is-filled .bmd-label-floating {
  top      : -1rem;
  left     : 0;
  font-size: 0.6875rem;
}

.AdminMAinControl .bmd-label-static {
  top      : 0.35rem;
  left     : 0;
  font-size: 0.875rem;
}

.AdminMAinControl .bmd-help {
  margin-top: 0;
  font-size : 0.75rem;
}

.AdminMAinControl .form-control.form-control-success,
.AdminMAinControl .form-control.form-control-warning,
.AdminMAinControl .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}

.AdminMAinControl .form-control.form-control-success,
.AdminMAinControl .form-control.form-control-success:focus,
.AdminMAinControl .bmd-form-group.is-focused .form-control.form-control-success,
.form-control.form-control-warning,
.AdminMAinControl .form-control.form-control-warning:focus,
.AdminMAinControl .bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger,
.AdminMAinControl .form-control.form-control-danger:focus,
.AdminMAinControl .bmd-form-group.is-focused .form-control.form-control-danger {
  padding-right      : 0;
  background-repeat  : no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.46875rem;
}

.AdminMAinControl .form-control.form-control-success:focus,
.AdminMAinControl .bmd-form-group.is-focused .form-control.form-control-success,
.form-control.form-control-warning:focus,
.AdminMAinControl .bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger:focus,
.AdminMAinControl .bmd-form-group.is-focused .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}




.AdminMAinControl .row {
  display: block;
}


.AdminMAinControl .card [class*="card-header-"]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
  border-radius: 3px;
  margin-top   : -20px;
  padding      : 15px;
}

.AdminMAinControl .card .card-header-primary .card-icon,
.AdminMAinControl .card .card-header-primary:not(.card-header-icon):not(.card-header-text),
.card .card-header-primary .card-text {
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%);
}

.AdminMAinControl .card .card-header-primary .card-icon,
.AdminMAinControl .card .card-header-primary .card-text,
.AdminMAinControl .card .card-header-primary:not(.card-header-icon):not(.card-header-text),
.AdminMAinControl .card.bg-primary,
.AdminMAinControl .card.card-rotate.bg-primary .front,
.AdminMAinControl .card.card-rotate.bg-primary .back {
  background: linear-gradient(60deg, #ab47bc, #8e24aa);
}

.AdminMAinControl .card [class*="card-header-"] {
  margin  : 0px 15px 0;
  padding : 0;
  position: relative;
}

.AdminMAinControl .card .card-header {
  z-index: 3 !important;
}



.AdminMAinControl .adminCard {
  padding: 30px;
  margin : 10px;
  border : 1px solid gray;
  float  : left;
}

.AdminMAinControl .adminCard .cardNumber {
  padding    : 15;
  text-align : center;
  font-weight: bold;
  font-size  : 23px;
}


.AdminMAinControl .adminCard .cardaciklama {
  padding    : 5;
  text-align : center;
  font-weight: bold;
  font-size  : 15px;
}








.AdminMAinControl .card {
  font-size: 16px;
}


.AdminMAinControl .rounded {
  border-radius: 6px !important;
}

.AdminMAinControl .navbar {
  border          : 0;
  border-radius   : 3px;
  padding         : 0.625rem 0;
  margin-bottom   : 20px;
  height          : auto !important;
  color           : #555;
  background-color: #fff !important;
  box-shadow      : 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
}

.AdminMAinControl .navbar .dropdown-item:hover,
.AdminMAinControl .navbar .dropdown-item:focus {
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4);
  background-color: #fff;
  color           : #555;
}

.AdminMAinControl.navbar .navbar-toggler .navbar-toggler-icon {
  background-color: #555;
}

.AdminMAinControl.navbar.fixed-top {
  border-radius: 0;
}

.AdminMAinControl .navbar .navbar-nav .nav-item .nav-link {
  position      : relative;
  color         : inherit;
  padding       : 0.9375rem;
  font-weight   : 400;
  font-size     : 12px;
  text-transform: uppercase;
  border-radius : 3px;
  line-height   : 20px;
}

.AdminMAinControl .navbar .navbar-nav .nav-item .nav-link:not(.btn-just-icon) .fa {
  position    : relative;
  top         : 2px;
  margin-top  : -4px;
  margin-right: 4px;
}

.AdminMAinControl .navbar .navbar-nav .nav-item .nav-link .material-icons,
.AdminMAinControl .navbar .navbar-nav .nav-item .nav-link .fa {
  font-size : 1.25rem;
  max-width : 24px;
  margin-top: -1.1em;
}

.AdminMAinControl.navbar .navbar-nav .nav-item .nav-link:not(.btn) .material-icons {
  margin-top  : -7px;
  top         : 3px;
  position    : relative;
  margin-right: 3px;
}

.AdminMAinControl .navbar .navbar-nav .nav-item .nav-link.profile-photo {
  padding: 0;
  margin : 0 3px;
}

.AdminMAinControl .navbar .navbar-nav .nav-item .nav-link.profile-photo:after {
  display: none;
}

.AdminMAinControl .navbar .navbar-nav .nav-item .nav-link.profile-photo .profile-photo-small {
  height: 40px;
  width : 40px;
}

.navbar .navbar-nav .nav-item .nav-link.profile-photo .ripple-container {
  border-radius: 50%;
}

.navbar .navbar-nav .dropdown-menu-right {
  transform-origin: 100% 0;
}

.AdminMAinControl .navbar .navbar-nav .nav-item.active .nav-link,
.AdminMAinControl .navbar .navbar-nav .nav-item.active .nav-link:hover,
.AdminMAinControl .navbar .navbar-nav .nav-item.active .nav-link:focus {
  color           : inherit;
  background-color: rgba(255, 255, 255, 0.1);
}

.AdminMAinControl .navbar .btn,
.AdminMAinControl .navbar .navbar-nav .nav-item .btn {
  margin-top   : 0;
  margin-bottom: 0;
}

.AdminMAinControl .navbar .navbar-toggler {
  cursor : pointer;
  outline: 0;
}

.AdminMAinControl .navbar .navbar-toggler .navbar-toggler-icon {
  width         : 22px;
  height        : 2px;
  vertical-align: middle;
  outline       : 0;
  display       : block;
  border-radius : 1px;
}

.AdminMAinControl .navbar .navbar-toggler .navbar-toggler-icon+.navbar-toggler-icon {
  margin-top: 4px;
}

.AdminMAinControl .navbar.navbar-absolute {
  position   : absolute;
  width      : 100%;
  padding-top: 10px;
  z-index    : 1029;
}

.AdminMAinControl .navbar .navbar-wrapper {
  display    : inline-flex;
  align-items: center;
}

.AdminMAinControl .navbar .navbar-brand {
  position   : relative;
  color      : inherit;
  height     : 50px;
  font-size  : 1.125rem;
  line-height: 30px;
  padding    : 0.625rem 0;
  font-weight: 300;
  margin-left: 1rem;
}

.AdminMAinControl .navbar>.container {
  flex: 1;
}

.AdminMAinControl .navbar.bg-primary {
  color           : #fff;
  background-color: #9c27b0 !important;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46);
}

.AdminMAinControl.navbar.bg-primary .dropdown-item:hover,
.AdminMAinControl .navbar.bg-primary .dropdown-item:focus {
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-color: #9c27b0;
  color           : #fff;
}

.AdminMAinControl .navbar.bg-primary .navbar-toggler .navbar-toggler-icon {
  background-color: #fff;
}

.AdminMAinControl .navbar.bg-info {
  color           : #fff;
  background-color: #00bcd4 !important;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46);
}

.AdminMAinControl.navbar.bg-info .dropdown-item:hover,
.AdminMAinControl .navbar.bg-info .dropdown-item:focus {
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
  background-color: #00bcd4;
  color           : #fff;
}

.AdminMAinControl .navbar.bg-info .navbar-toggler .navbar-toggler-icon {
  background-color: #fff;
}

.AdminMAinControl .navbar.bg-warning {
  color           : #fff;
  background-color: #ff9800 !important;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46);
}

.AdminMAinControl .navbar.bg-warning .dropdown-item:hover,
.AdminMAinControl .navbar.bg-warning .dropdown-item:focus {
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
  background-color: #ff9800;
  color           : #fff;
}

.AdminMAinControl .navbar.bg-warning .navbar-toggler .navbar-toggler-icon {
  background-color: #fff;
}

.AdminMAinControl .navbar.bg-rose {
  color           : #fff;
  background-color: #e91e63 !important;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46);
}

.AdminMAinControl .navbar.bg-rose .dropdown-item:hover,
.AdminMAinControl .navbar.bg-rose .dropdown-item:focus {
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
  background-color: #e91e63;
  color           : #fff;
}

.AdminMAinControl .navbar.bg-rose .navbar-toggler .navbar-toggler-icon {
  background-color: #fff;
}

.AdminMAinControl .navbar.bg-danger {
  color           : #fff;
  background-color: #f44336 !important;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46);
}

.AdminMAinControl .navbar.bg-danger .dropdown-item:hover,
.AdminMAinControl .navbar.bg-danger .dropdown-item:focus {
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
  background-color: #f44336;
  color           : #fff;
}

.AdminMAinControl .navbar.bg-danger .navbar-toggler .navbar-toggler-icon {
  background-color: #fff;
}

.AdminMAinControl .navbar.bg-success {
  color           : #fff;
  background-color: #4caf50 !important;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46);
}

.AdminMAinControl .navbar.bg-success .dropdown-item:hover,
.AdminMAinControl .navbar.bg-success .dropdown-item:focus {
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
  background-color: #4caf50;
  color           : #fff;
}

.AdminMAinControl .navbar.bg-success .navbar-toggler .navbar-toggler-icon {
  background-color: #fff;
}

.AdminMAinControl .navbar.bg-dark {
  color           : #fff;
  background-color: #212121 !important;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46);
}

.AdminMAinControl .navbar.bg-dark .dropdown-item:hover,
.AdminMAinControl .navbar.bg-dark .dropdown-item:focus {
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4);
  background-color: #212121;
  color           : #fff;
}

.AdminMAinControl .navbar.bg-dark .navbar-toggler .navbar-toggler-icon {
  background-color: #fff;
}

.AdminMAinControl .navbar.navbar-transparent {
  background-color: transparent !important;
  box-shadow      : none;
}

.AdminMAinControl .navbar .notification {
  position      : absolute;
  top           : 5px;
  border        : 1px solid #FFF;
  right         : 10px;
  font-size     : 9px;
  background    : #f44336;
  color         : #FFFFFF;
  min-width     : 20px;
  padding       : 0px 5px;
  height        : 20px;
  border-radius : 10px;
  text-align    : center;
  line-height   : 19px;
  vertical-align: middle;
  display       : block;
}

.AdminMAinControl .navbar .navbar-minimize {
  padding: 3px 0 0 15px;
}

.AdminMAinControl .navbar .collapse .navbar-nav .nav-item .nav-link {
  position      : relative;
  padding       : 10px 15px;
  font-weight   : 400;
  font-size     : 12px;
  text-transform: uppercase;
  border-radius : 3px;
  line-height   : 20px;
  margin-left   : 5px;
  color         : inherit;
}

.AdminMAinControl .navbar .collapse .navbar-nav .nav-item .nav-link:not(.btn-just-icon) .fa {
  position    : relative;
  top         : 2px;
  margin-top  : -4px;
  margin-right: 4px;
}

.AdminMAinControl .navbar .collapse .navbar-nav .nav-item .nav-link .material-icons,
.AdminMAinControl .navbar .collapse .navbar-nav .nav-item .nav-link .fa {
  font-size : 1.25rem;
  max-width : 24px;
  margin-top: -1.1em;
}

.navbar .collapse .navbar-nav .nav-item .nav-link:not(.btn) .material-icons {
  margin-top  : -3px;
  top         : 0px;
  position    : relative;
  margin-right: 3px;
}

.AdminMAinControl .navbar .collapse .navbar-nav .nav-item .nav-link .notification {
  top: 0px;
}

.AdminMAinControl .off-canvas-sidebar .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  padding-top   : 15px;
  padding-bottom: 15px;
  font-weight   : 500;
  font-size     : 12px;
  text-transform: uppercase;
  border-radius : 3px;
  color         : #fff;
  margin        : 0 15px;
}

.AdminMAinControl .off-canvas-sidebar .navbar .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
  background: rgba(200, 200, 200, 0.2);
}

.AdminMAinControl .off-canvas-sidebar .navbar.navbar-transparent {
  padding-top: 25px !important;
}

.AdminMAinControl .nav-tabs {
  border       : 0;
  border-radius: 3px;
  padding      : 0 15px;
}

.AdminMAinControl .nav-tabs .nav-item .nav-link {
  position        : relative;
  color           : #fff;
  border          : 0;
  margin          : 0;
  border-radius   : 3px;
  line-height     : 24px;
  text-transform  : uppercase;
  font-size       : 12px;
  padding         : 10px 15px;
  background-color: transparent;
  transition      : 0.3s background-color 0s;
}

.AdminMAinControl .nav-tabs .nav-item .nav-link:hover {
  border: 0;
}

.AdminMAinControl .nav-tabs .nav-item .nav-link,
.AdminMAinControl.nav-tabs .nav-item .nav-link:hover,
.AdminMAinControl .nav-tabs .nav-item .nav-link:focus {
  border     : 0 !important;
  color      : #fff !important;
  font-weight: 500;
}

.AdminMAinControl .nav-tabs .nav-item.disabled .nav-link,
.AdminMAinControl .nav-tabs .nav-item.disabled .nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}

.AdminMAinControl .nav-tabs .nav-item .material-icons {
  margin: -1px 5px 0 0;
}

.AdminMAinControl .nav-tabs .nav-item .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  transition      : 0.3s background-color 0.2s;
}

.AdminMAinControl .nav-tabs .nav-link {
  border-bottom: 0.214rem solid transparent;
}

.AdminMAinControl .nav-tabs .nav-link {
  color: #555555;
}

.AdminMAinControl .nav-tabs .nav-link.active {
  color       : #333333;
  border-color: #9c27b0;
}

.AdminMAinControl .nav-tabs .nav-link.active:hover,
.AdminMAinControl .nav-tabs .nav-link.active:focus {
  border-color: #9c27b0;
}

.AdminMAinControl .nav-tabs .nav-link.disabled {
  color: #999999;
}

.AdminMAinControl .nav-tabs .nav-link.disabled,
.AdminMAinControl .nav-tabs .nav-link.disabled:hover,
.AdminMAinControl .nav-tabs .nav-link.disabled:focus {
  color: #999999;
}

.nav-tabs.header-primary .nav-link {
  color: white;
}

.AdminMAinControl .nav-tabs.header-primary .nav-link.active {
  color       : #fff;
  border-color: #fff;
}

.AdminMAinControl .nav-tabs.header-primary .nav-link.active:hover,
.AdminMAinControl .nav-tabs.header-primary .nav-link.active:focus {
  border-color: #fff;
}

.AdminMAinControl .nav-tabs.header-primary .nav-link.disabled {
  color: rgba(255, 255, 255, 0.84);
}

.AdminMAinControl .nav-tabs.header-primary .nav-link.disabled,
.AdminMAinControl .nav-tabs.header-primary .nav-link.disabled:hover,
.AdminMAinControl .nav-tabs.header-primary .nav-link.disabled:focus {
  color: rgba(255, 255, 255, 0.84);
}

.AdminMAinControl .nav-tabs.bg-inverse .nav-link {
  color: white;
}

.AdminMAinControl .nav-tabs.bg-inverse .nav-link.active {
  color       : #fff;
  border-color: #fff;
}

.AdminMAinControl .nav-tabs.bg-inverse .nav-link.active:hover,
.AdminMAinControl .nav-tabs.bg-inverse .nav-link.active:focus {
  border-color: #fff;
}

.AdminMAinControl .nav-tabs.bg-inverse .nav-link.disabled {
  color: rgba(255, 255, 255, 0.84);
}

.AdminMAinControl .nav-tabs.bg-inverse .nav-link.disabled,
.AdminMAinControl .nav-tabs.bg-inverse .nav-link.disabled:hover,
.AdminMAinControl .nav-tabs.bg-inverse .nav-link.disabled:focus {
  color: rgba(255, 255, 255, 0.84);
}

.AdminMAinControl .card-nav-tabs {
  margin-top: 45px;
}

.AdminMAinControl .card-nav-tabs .card-header {
  margin-top: -30px !important;
}

.AdminMAinControl .tab-content .tab-pane .td-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.AdminMAinControl .card .tab-content .form-check {
  margin-top: 6px;
}

.AdminMAinControl .tooltip-arrow {
  display: none;
}

.AdminMAinControl .tooltip.show {
  opacity          : 1;
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform   : translate3d(0, 0px, 0);
  -o-transform     : translate3d(0, 0px, 0);
  -ms-transform    : translate3d(0, 0px, 0);
  transform        : translate3d(0, 0px, 0);
}

.AdminMAinControl .tooltip {
  opacity          : 0;
  transition       : opacity, transform .2s ease;
  -webkit-transform: translate3d(0, 5px, 0);
  -moz-transform   : translate3d(0, 5px, 0);
  -o-transform     : translate3d(0, 5px, 0);
  -ms-transform    : translate3d(0, 5px, 0);
  transform        : translate3d(0, 5px, 0);
  font-size        : 0.875rem;
}

.AdminMAinControl .tooltip.bs-tooltip-top .arrow::before,
.AdminMAinControl .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.AdminMAinControl .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #fff;
}

.AdminMAinControl .tooltip.bs-tooltip-right .arrow::before,
.AdminMAinControl .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.AdminMAinControl .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #fff;
}

.AdminMAinControl .tooltip.bs-tooltip-left .arrow::before,
.AdminMAinControl .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.AdminMAinControl .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #fff;
}

.AdminMAinControl .tooltip.bs-tooltip-bottom .arrow::before,
.AdminMAinControl .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.AdminMAinControl .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #fff;
}

.AdminMAinControl .tooltip-inner {
  padding  : 10px 15px;
  min-width: 130px;
}

.AdminMAinControl .popover,
.AdminMAinControl .tooltip-inner {
  line-height  : 1.5em;
  background   : #fff;
  border       : none;
  border-radius: 3px;
  box-shadow   : 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  color        : #555;
}

.AdminMAinControl .popover {
  padding   : 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.AdminMAinControl .popover.left>.arrow,
.AdminMAinControl .popover.right>.arrow,
.AdminMAinControl .popover.top>.arrow,
.AdminMAinControl .popover.bottom>.arrow {
  border: none;
}

.AdminMAinControl .popover-header {
  background-color: #fff;
  border          : none;
  padding         : 15px 15px 5px;
  font-size       : 1.125rem;
  margin          : 0;
  color           : #555;
}

.AdminMAinControl .popover-body {
  padding    : 10px 15px 15px;
  line-height: 1.4;
  color      : #555;
}

.AdminMAinControl .dropdown-menu {
  display         : none;
  padding         : 0.3125rem 0;
  border          : 0;
  opacity         : 0;
  transform       : scale(0);
  transform-origin: 0 0;
  will-change     : transform, opacity;
  transition      : transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow      : 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.AdminMAinControl .dropdown-menu.showing {
  animation-name           : bmd-dropdown-animation;
  animation-duration       : 0.3s;
  animation-fill-mode      : forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.AdminMAinControl .open>.dropdown-menu,
.AdminMAinControl .dropdown-menu.show {
  display  : block;
  opacity  : 1;
  transform: scale(1);
}

.AdminMAinControl .dropdown-menu.hiding {
  display  : block;
  opacity  : 0;
  transform: scale(0);
}

.AdminMAinControl .dropdown-menu[x-placement="bottom-start"] {
  transform-origin: 0 0;
}

.AdminMAinControl .dropdown-menu[x-placement="bottom-end"] {
  transform-origin: 100% 0;
}

.AdminMAinControl .dropdown-menu[x-placement="top-start"] {
  transform-origin: 0 100%;
}

.AdminMAinControl .dropdown-menu[x-placement="top-end"] {
  transform-origin: 100% 100%;
}

.AdminMAinControl .dropdown-menu .disabled>a {
  color: #777;
}

.AdminMAinControl .dropdown-menu .disabled>a:focus,
.AdminMAinControl .dropdown-menu .disabled>a:hover {
  text-decoration : none;
  background-color: transparent;
  background-image: none;
  color           : #777;
}

.AdminMAinControl .dropdown-menu.dropdown-with-icons .dropdown-item {
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
}

.AdminMAinControl .dropdown-menu.dropdown-with-icons .dropdown-item .material-icons {
  vertical-align: middle;
  font-size     : 24px;
  position      : relative;
  margin-top    : -4px;
  top           : 1px;
  margin-right  : 12px;
  opacity       : .5;
}

.AdminMAinControl .dropdown-menu .dropdown-item,
.AdminMAinControl .dropdown-menu li>a {
  position          : relative;
  width             : auto;
  display           : flex;
  flex-flow         : nowrap;
  align-items       : center;
  color             : #333;
  font-weight       : normal;
  text-decoration   : none;
  font-size         : .8125rem;
  border-radius     : 0.125rem;
  margin            : 0 0.3125rem;
  -webkit-transition: all 150ms linear;
  -moz-transition   : all 150ms linear;
  -o-transition     : all 150ms linear;
  -ms-transition    : all 150ms linear;
  transition        : all 150ms linear;
  min-width         : 7rem;
  padding           : 0.625rem 1.25rem;
  overflow          : hidden;
  line-height       : 1.428571;
  text-overflow     : ellipsis;
  word-wrap         : break-word;
}

@media (min-width: 768px) {

  .AdminMAinControl .dropdown-menu .dropdown-item,
  .AdminMAinControl .dropdown-menu li>a {
    padding-right: 1.5rem;
    padding-left : 1.5rem;
  }
}

.AdminMAinControl .dropdown-menu .dropdown-item:hover,
.AdminMAinControl .dropdown-menu .dropdown-item:focus,
.AdminMAinControl .dropdown-menu a:hover,
.AdminMAinControl .dropdown-menu a:focus,
.AdminMAinControl .dropdown-menu a:active {
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-color: #9c27b0;
  color           : #FFFFFF;
}

.AdminMAinControl a[data-toggle="collapse"][aria-expanded="true"] .caret,
.AdminMAinControl a[aria-expanded="true"] .caret,
.AdminMAinControl .dropdown.open .caret,
.AdminMAinControl .dropup.open .caret,
.AdminMAinControl .btn-group.bootstrap-select.open .caret {
  filter           : progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform    : rotate(180deg);
  transform        : rotate(180deg);
}

.AdminMAinControl .dropdown-toggle.bmd-btn-icon::after,
.AdminMAinControl .dropdown-toggle.bmd-btn-fab::after {
  display: none;
}

.AdminMAinControl .dropdown-toggle.bmd-btn-icon~.dropdown-menu.dropdown-menu-top-left,
.AdminMAinControl .dropdown-toggle.bmd-btn-icon~.dropdown-menu.dropdown-menu-top-right,
.AdminMAinControl .dropdown-toggle.bmd-btn-fab~.dropdown-menu.dropdown-menu-top-left,
.AdminMAinControl .dropdown-toggle.bmd-btn-fab~.dropdown-menu.dropdown-menu-top-right {
  bottom: 2rem;
}

.AdminMAinControl .dropdown-toggle:after {
  will-change: transform;
  transition : transform 150ms linear;
}

.AdminMAinControl .dropdown-toggle.bmd-btn-fab-sm~.dropdown-menu.dropdown-menu-top-left,
.AdminMAinControl .dropdown-toggle.bmd-btn-fab-sm~.dropdown-menu.dropdown-menu-top-right {
  bottom: 2.5rem;
}

.dropdown-toggle.bmd-btn-icon~.dropdown-menu {
  margin: 0;
}

.AdminMAinControl .show>.dropdown-toggle:not(.dropdown-item):after {
  filter           : progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform    : rotate(180deg);
  transform        : rotate(180deg);
}

.AdminMAinControl .dropdown-header {
  font-size     : 0.75rem;
  padding-top   : .625rem;
  padding-bottom: .625rem;
  text-transform: none;
  color         : #777;
  line-height   : 1.428571;
  font-weight   : inherit;
}

@keyframes bmd-dropdown-animation {
  from {
    opacity  : 0;
    transform: scale(0);
  }

  to {
    opacity  : 1;
    transform: scale(1);
  }
}

.AdminMAinControl .dropdown-menu.bootstrap-datetimepicker-widget {
  opacity                   : 0;
  transform                 : scale(0);
  transition-duration       : 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin          : 0 0;
  will-change               : transform, opacity;
  top                       : 0;
}

.AdminMAinControl .dropdown-menu.bootstrap-datetimepicker-widget.top {
  transform-origin: 0 100%;
}

.AdminMAinControl .dropdown-menu.bootstrap-datetimepicker-widget.open {
  opacity  : 1;
  transform: scale(1);
  top      : 0;
}

.AdminMAinControl .togglebutton {
  vertical-align: middle;
}

.AdminMAinControl .togglebutton,
.AdminMAinControl .togglebutton label,
.AdminMAinControl .togglebutton input,
.AdminMAinControl .togglebutton .toggle {
  user-select: none;
}

.AdminMAinControl .togglebutton label {
  cursor: pointer;
  color : rgba(0, 0, 0, 0.26);
}

.AdminMAinControl .form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}

.AdminMAinControl .form-group.is-focused .togglebutton label:hover,
.AdminMAinControl .form-group.is-focused .togglebutton label:focus {
  color: rgba(0, 0, 0, .54);
}

.AdminMAinControl fieldset[disabled] .form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}

.AdminMAinControl .togglebutton label input[type=checkbox] {
  opacity: 0;
  width  : 0;
  height : 0;
}

.AdminMAinControl .togglebutton label .toggle {
  text-align : left;
  margin-left: 5px;
}

.AdminMAinControl .togglebutton label .toggle,
.AdminMAinControl .togglebutton label input[type=checkbox][disabled]+.toggle {
  content         : "";
  display         : inline-block;
  width           : 30px;
  height          : 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius   : 15px;
  margin-right    : 15px;
  transition      : background 0.3s ease;
  vertical-align  : middle;
}

.AdminMAinControl .togglebutton label .toggle:after {
  content         : "";
  display         : inline-block;
  width           : 20px;
  height          : 20px;
  background-color: #FFFFFF;
  border-radius   : 20px;
  position        : relative;
  box-shadow      : 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left            : -5px;
  top             : -2.5px;
  border          : 1px solid rgba(0, 0, 0, .54);
  transition      : left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}

.AdminMAinControl .togglebutton label input[type=checkbox][disabled]+.toggle:after,
.AdminMAinControl .togglebutton label input[type=checkbox][disabled]:checked+.toggle:after {
  background-color: #BDBDBD;
}

.AdminMAinControl .togglebutton label input[type=checkbox]+.toggle:active:after,
.AdminMAinControl .togglebutton label input[type=checkbox][disabled]+.toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}

.AdminMAinControl .togglebutton label input[type=checkbox]:checked+.toggle:after {
  left: 15px;
}

.AdminMAinControl .togglebutton label input[type=checkbox]:checked+.toggle {
  background-color: rgba(156, 39, 176, 0.7);
}

.AdminMAinControl .togglebutton label input[type=checkbox]:checked+.toggle:after {
  border-color: #9c27b0;
}

.AdminMAinControl .togglebutton label input[type=checkbox]:checked+.toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(156, 39, 176, 0.1);
}

.AdminMAinControl .ripple {
  position: relative;
}

.AdminMAinControl .ripple-container {
  position      : absolute;
  top           : 0;
  left          : 0;
  z-index       : 1;
  width         : 100%;
  height        : 100%;
  overflow      : hidden;
  pointer-events: none;
  border-radius : inherit;
}

.AdminMAinControl .ripple-container .ripple-decorator {
  position        : absolute;
  width           : 20px;
  height          : 20px;
  margin-top      : -10px;
  margin-left     : -10px;
  pointer-events  : none;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius   : 100%;
  opacity         : 0;
  transform       : scale(1);
  transform-origin: 50%;
}

.AdminMAinControl .ripple-container .ripple-decorator.ripple-on {
  opacity   : 0.1;
  transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}

.AdminMAinControl .ripple-container .ripple-decorator.ripple-out {
  opacity   : 0;
  transition: opacity 0.1s linear 0s !important;
}

.AdminMAinControl .footer {
  padding   : 0.9375rem 0;
  text-align: center;
  display   : -webkit-flex;
  /* Safari */
  /* Safari 6.1+ */
  display   : flex;
}

.AdminMAinControl .footer ul {
  margin-bottom: 0;
  padding      : 0;
  list-style   : none;
}

.AdminMAinControl .footer ul li {
  display: inline-block;
}

.AdminMAinControl .footer ul li a {
  color          : inherit;
  padding        : 0.9375rem;
  font-weight    : 500;
  font-size      : 12px;
  text-transform : uppercase;
  border-radius  : 3px;
  text-decoration: none;
  position       : relative;
  display        : block;
}

.AdminMAinControl .footer ul li a:hover {
  text-decoration: none;
}

.AdminMAinControl .footer ul li .btn {
  margin: 0;
}

.AdminMAinControl .footer ul.links-horizontal:first-child a {
  padding-left: 0;
}

.AdminMAinControl .footer ul.links-horizontal:last-child a {
  padding-right: 0;
}

.AdminMAinControl .footer ul.links-vertical li {
  display     : block;
  margin-left : -5px;
  margin-right: -5px;
}

.AdminMAinControl .footer ul.links-vertical li a {
  padding: 5px;
}

.AdminMAinControl .footer .social-buttons a,
.AdminMAinControl .footer .social-buttons .btn {
  margin-top   : 5px;
  margin-bottom: 5px;
}

.AdminMAinControl .footer .footer-brand {
  float      : left;
  height     : 50px;
  padding    : 15px 15px;
  font-size  : 18px;
  line-height: 20px;
  margin-left: -15px;
}

.AdminMAinControl .footer .footer-brand:hover,
.AdminMAinControl .footer .footer-brand:focus {
  color: #3C4858;
}

.AdminMAinControl .footer .copyright {
  padding: 15px 0;
}

.AdminMAinControl .footer .copyright .material-icons {
  font-size: 18px;
  position : relative;
  top      : 3px;
}

.AdminMAinControl .footer .pull-center {
  display: inline-block;
  float  : none;
}

.AdminMAinControl .off-canvas-sidebar .footer {
  position: absolute;
  bottom  : 0;
  width   : 100%;
}

@media screen and (min-width: 768px) {
  .AdminMAinControl .footer .copyright {
    padding-right: 15px;
  }
}

.AdminMAinControl.wrapper {
  background-color: #eeeeee;
  color           : #3C4858;
  font-weight     : 300;
  position        : relative;
  top             : 0;
  height          : 100%;
  overflow        : auto;
  min-height      : 100%;
  display         : block;
  padding         : 0;
}

.AdminMAinControl .sidebar {
  position  : fixed;
  top       : 0;
  bottom    : 0;
  left      : 0;
  z-index   : 2;
  width     : 260px;
  background: #fff;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.AdminMAinControl .sidebar .caret {
  display       : inline-block;
  width         : 0;
  height        : 0;
  margin-left   : 2px;
  vertical-align: middle;
  border-top    : 4px dashed;
  border-top    : 4px solid\9;
  border-right  : 4px solid transparent;
  border-left   : 4px solid transparent;
}

.sidebar[data-background-color="black"] {
  background-color: #191919;
}

.AdminMAinControl .sidebar .sidebar-wrapper {
  position      : relative;
  height        : calc(100vh - 75px);
  overflow      : auto;
  width         : 260px;
  z-index       : 4;
  padding-bottom: 30px;
}

.AdminMAinControl .sidebar .sidebar-wrapper .dropdown .dropdown-backdrop {
  display: none !important;
}

.AdminMAinControl .sidebar .sidebar-wrapper .navbar-form {
  border    : none;
  box-shadow: none;
}

.AdminMAinControl .sidebar .sidebar-wrapper .navbar-form .input-group {
  font-size   : 1.7em;
  height      : 36px;
  width       : 78%;
  padding-left: 17px;
}

.AdminMAinControl .sidebar .sidebar-wrapper>.nav [data-toggle="collapse"]~div>ul>li>a span,
.AdminMAinControl .sidebar .sidebar-wrapper .user .user-info [data-toggle="collapse"]~div>ul>li>a span {
  display: inline-block;
}

.AdminMAinControl .sidebar .sidebar-wrapper>.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
.AdminMAinControl .sidebar .sidebar-wrapper .user .user-info [data-toggle="collapse"]~div>ul>li>a .sidebar-normal {
  margin     : 0;
  position   : relative;
  transform  : translateX(0px);
  opacity    : 1;
  white-space: nowrap;
  display    : block;
}

.AdminMAinControl .sidebar .sidebar-wrapper>.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-mini,
.AdminMAinControl .sidebar .sidebar-wrapper .user .user-info [data-toggle="collapse"]~div>ul>li>a .sidebar-mini {
  text-transform: uppercase;
  width         : 30px;
  margin-right  : 15px;
  text-align    : center;
  letter-spacing: 1px;
  position      : relative;
  float         : left;
  display       : inherit;
}

.AdminMAinControl .sidebar .sidebar-wrapper>.nav [data-toggle="collapse"]~div>ul>li>a i,
.AdminMAinControl .sidebar .sidebar-wrapper .user .user-info [data-toggle="collapse"]~div>ul>li>a i {
  font-size  : 17px;
  line-height: 20px;
  width      : 26px;
}

.AdminMAinControl .sidebar .logo-tim {
  border-radius: 50%;
  border       : 1px solid #333;
  display      : block;
  height       : 61px;
  width        : 61px;
  float        : left;
  overflow     : hidden;
}

.AdminMAinControl .sidebar .logo-tim img {
  width : 60px;
  height: 60px;
}

.AdminMAinControl .sidebar .nav {
  margin-top: 20px;
  display   : block;
}

.AdminMAinControl .sidebar .nav .caret {
  margin-top: 13px;
  position  : absolute;
  right     : 6px;
}

.AdminMAinControl .sidebar .nav li>a:hover,
.AdminMAinControl .sidebar .nav li>a:focus {
  background-color: transparent;
  outline         : none;
}

.AdminMAinControl .sidebar .nav li:first-child>a {
  margin: 0 15px;
}

.AdminMAinControl .sidebar .nav li:hover>a,
.AdminMAinControl .sidebar .nav li .dropdown-menu a:hover,
.AdminMAinControl .sidebar .nav li .dropdown-menu a:focus,
.AdminMAinControl .sidebar .nav li.active>[data-toggle="collapse"] {
  background-color: rgba(200, 200, 200, 0.2);
  color           : #3C4858;
  box-shadow      : none;
}

.AdminMAinControl .sidebar .nav li.active>[data-toggle="collapse"] i {
  color: #a9afbb;
}

.AdminMAinControl .sidebar .nav li.active>a,
.AdminMAinControl .sidebar .nav li.active>a i {
  color: #fff;
}

.AdminMAinControl .sidebar .nav li.separator {
  margin: 15px 0;
}

.AdminMAinControl .sidebar .nav li.separator:after {
  width           : calc(100% - 30px);
  content         : "";
  position        : absolute;
  height          : 1px;
  left            : 15px;
  background-color: rgba(180, 180, 180, 0.3);
}

.AdminMAinControl .sidebar .nav li.separator+li {
  margin-top: 31px;
}

.AdminMAinControl .sidebar .nav p {
  margin     : 0;
  line-height: 30px;
  font-size  : 14px;
  position   : relative;
  display    : block;
  height     : auto;
  white-space: nowrap;
}

.AdminMAinControl .sidebar .nav i {
  font-size   : 24px;
  float       : left;
  margin-right: 15px;
  line-height : 30px;
  width       : 30px;
  text-align  : center;
  color       : #a9afbb;
}

.AdminMAinControl .sidebar .nav li a,
.AdminMAinControl .sidebar .nav li .dropdown-menu a {
  margin        : 10px 15px 0;
  border-radius : 3px;
  color         : #3C4858;
  padding-left  : 10px;
  padding-right : 10px;
  text-transform: capitalize;
  font-size     : 13px;
  padding       : 10px 15px;
}

.AdminMAinControl .sidebar .sidebar-background {
  position           : absolute;
  z-index            : 1;
  height             : 100%;
  width              : 100%;
  display            : block;
  top                : 0;
  left               : 0;
  background-size    : cover;
  background-position: center center;
}

.AdminMAinControl .sidebar .sidebar-background:after {
  position  : absolute;
  z-index   : 3;
  width     : 100%;
  height    : 100%;
  content   : "";
  display   : block;
  background: #FFFFFF;
  opacity   : .93;
}

.AdminMAinControl .sidebar .logo {
  padding : 15px 0px;
  margin  : 0;
  display : block;
  position: relative;
  z-index : 4;
}

.AdminMAinControl .sidebar .logo:after {
  content         : '';
  position        : absolute;
  bottom          : 0;
  right           : 15px;
  height          : 1px;
  width           : calc(100% - 30px);
  background-color: rgba(180, 180, 180, 0.3);
}

.AdminMAinControl .sidebar .logo p {
  float      : left;
  font-size  : 20px;
  margin     : 10px 10px;
  color      : #fff;
  line-height: 20px;
}

.AdminMAinControl .sidebar .logo .simple-text {
  text-transform: uppercase;
  padding       : 5px 0px;
  display       : inline-block;
  font-size     : 18px;
  color         : #3C4858;
  white-space   : nowrap;
  font-weight   : 400;
  line-height   : 30px;
  overflow      : hidden;
  text-align    : center;
  display       : block;
}

.AdminMAinControl .sidebar .logo-tim {
  border-radius: 50%;
  border       : 1px solid #333;
  display      : block;
  height       : 61px;
  width        : 61px;
  float        : left;
  overflow     : hidden;
}

.AdminMAinControl .sidebar .logo-tim img {
  width : 60px;
  height: 60px;
}

.AdminMAinControl .sidebar[data-background-color="black"] .nav .nav-item .nav-link {
  color: #fff;
}

.AdminMAinControl .sidebar[data-background-color="black"] .nav .nav-item i {
  color: rgba(255, 255, 255, 0.8);
}

.AdminMAinControl .sidebar[data-background-color="black"] .nav .nav-item.active [data-toggle="collapse"],
.AdminMAinControl .sidebar[data-background-color="black"] .nav .nav-item:hover [data-toggle="collapse"] {
  color: #fff;
}

.AdminMAinControl .sidebar[data-background-color="black"] .nav .nav-item.active [data-toggle="collapse"] i,
.AdminMAinControl .sidebar[data-background-color="black"] .nav .nav-item:hover [data-toggle="collapse"] i {
  color: rgba(255, 255, 255, 0.8);
}

.AdminMAinControl .sidebar[data-background-color="black"] .user a {
  color: #fff;
}

.AdminMAinControl .sidebar[data-background-color="black"] .simple-text {
  color: #fff;
}

.AdminMAinControl .sidebar[data-background-color="black"] .sidebar-background:after {
  background: #000;
  opacity   : .8;
}

.AdminMAinControl .sidebar[data-background-color="black"] .nav li .dropdown-menu .dropdown-item {
  color: #fff;
}

.AdminMAinControl .sidebar[data-color="purple"] li.active>a {
  background-color: #9c27b0;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
}

.AdminMAinControl .sidebar[data-color="azure"] li.active>a {
  background-color: #00bcd4;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
}

.AdminMAinControl .sidebar[data-color="green"] li.active>a {
  background-color: #4caf50;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}

.AdminMAinControl .sidebar[data-color="orange"] li.active>a {
  background-color: #ff9800;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
}

.AdminMAinControl .sidebar[data-color="danger"] li.active>a {
  background-color: #f44336;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
}

.AdminMAinControl .sidebar[data-color="rose"] li.active>a {
  background-color: #e91e63;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
}

.AdminMAinControl .sidebar[data-color="white"] li.active>a {
  background-color: #fff;
  box-shadow      : 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4);
}

.AdminMAinControl .sidebar[data-color="white"] .nav .nav-item.active>a:not([data-toggle="collapse"]) {
  color     : #3C4858;
  opacity   : 1;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(60, 72, 88, 0.4);
}

.AdminMAinControl .sidebar[data-color="white"] .nav .nav-item.active>a:not([data-toggle="collapse"]) i {
  color: rgba(60, 72, 88, 0.8);
}

.AdminMAinControl .sidebar[data-background-color="red"] .nav .nav-item .nav-link {
  color: #fff;
}

.AdminMAinControl .sidebar[data-background-color="red"] .nav .nav-item i {
  color: rgba(255, 255, 255, 0.8);
}

.AdminMAinControl .sidebar[data-background-color="red"] .nav .nav-item.active [data-toggle="collapse"],
.AdminMAinControl .sidebar[data-background-color="red"] .nav .nav-item:hover [data-toggle="collapse"] {
  color: #fff;
}

.AdminMAinControl .sidebar[data-background-color="red"] .nav .nav-item.active [data-toggle="collapse"] i,
.AdminMAinControl .sidebar[data-background-color="red"] .nav .nav-item:hover [data-toggle="collapse"] i {
  color: rgba(255, 255, 255, 0.8);
}

.AdminMAinControl .sidebar[data-background-color="red"] .user a {
  color: #fff;
}

.AdminMAinControl .sidebar[data-background-color="red"] .simple-text {
  color: #fff;
}

.AdminMAinControl .sidebar[data-background-color="red"] .sidebar-background:after {
  background: #f44336;
  opacity   : .8;
}

.AdminMAinControl .sidebar[data-background-color="red"] .user:after,
.AdminMAinControl .sidebar[data-background-color="red"] .logo:after,
.AdminMAinControl .sidebar[data-background-color="red"] .nav li.separator:after {
  background-color: rgba(255, 255, 255, 0.3);
}

.AdminMAinControl .sidebar[data-background-color="red"] .nav li:hover:not(.active)>a,
.AdminMAinControl .sidebar[data-background-color="red"] .nav li.active>[data-toggle="collapse"] {
  background-color: rgba(255, 255, 255, 0.1);
}

.AdminMAinControl .sidebar[data-image]:after,
.AdminMAinControl .sidebar.has-image:after {
  opacity: .77;
}

.AdminMAinControl .off-canvas-sidebar .navbar-collapse .nav>li>a,
.AdminMAinControl .off-canvas-sidebar .navbar-collapse .nav>li>a:hover {
  color : #fff;
  margin: 0 15px;
}

.AdminMAinControl .off-canvas-sidebar .navbar-collapse .nav>li>a:focus,
.AdminMAinControl .off-canvas-sidebar .navbar-collapse .nav>li>a:hover {
  background: rgba(200, 200, 200, 0.2);
}

.AdminMAinControl .main-panel {
  position  : relative;
  float     : right;
  width     : calc(100% - 260px);
  transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.AdminMAinControl .main-panel>.content {
  margin-top: 70px;
  padding   : 30px 15px;
  min-height: calc(100vh - 123px);
}

.AdminMAinControl .main-panel>.footer {
  border-top: 1px solid #e7e7e7;
}

.AdminMAinControl .main-panel>.navbar {
  margin-bottom: 0;
}

.AdminMAinControl .main-panel .header {
  margin-bottom: 30px;
}

.AdminMAinControl .main-panel .header .title {
  margin-top   : 10px;
  margin-bottom: 10px;
}

.AdminMAinControl .perfect-scrollbar-on .sidebar,
.AdminMAinControl .perfect-scrollbar-on .main-panel {
  height    : 100%;
  max-height: 100%;
}

.AdminMAinControl .sidebar,
.AdminMAinControl .main-panel,
.AdminMAinControl .sidebar-wrapper {
  -webkit-transition-property       : top, bottom, width;
  transition-property               : top, bottom, width;
  -webkit-transition-duration       : .2s, .2s, .35s;
  transition-duration               : .2s, .2s, .35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function        : linear, linear, ease;
  -webkit-overflow-scrolling        : touch;
}

.AdminMAinControl .visible-on-sidebar-regular {
  display: inline-block !important;
}

.AdminMAinControl .visible-on-sidebar-mini {
  display: none !important;
}

@media (min-width: 991px) {
  .AdminMAinControl .sidebar-mini .visible-on-sidebar-regular {
    display: none !important;
  }

  .AdminMAinControl .sidebar-mini .visible-on-sidebar-mini {
    display: inline-block !important;
  }

  .AdminMAinControl .sidebar-mini .sidebar,
  .AdminMAinControl .sidebar-mini .sidebar .sidebar-wrapper {
    width: 80px;
  }

  .AdminMAinControl .sidebar-mini .main-panel {
    width: calc(100% - 80px);
  }

  .AdminMAinControl .sidebar-mini .sidebar {
    display    : block;
    font-weight: 200;
    z-index    : 9999;
  }

  .AdminMAinControl .sidebar-mini .sidebar .logo a.logo-normal {
    opacity          : 0;
    -webkit-transform: translate3d(-25px, 0, 0);
    -moz-transform   : translate3d(-25px, 0, 0);
    -o-transform     : translate3d(-25px, 0, 0);
    -ms-transform    : translate3d(-25px, 0, 0);
    transform        : translate3d(-25px, 0, 0);
  }

  .AdminMAinControl .sidebar-mini .sidebar .sidebar-wrapper>.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
  .AdminMAinControl .sidebar-mini .sidebar .sidebar-wrapper .user .user-info [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
  .AdminMAinControl .sidebar-mini .sidebar .sidebar-wrapper .user .user-info>a>span,
  .AdminMAinControl .sidebar-mini .sidebar .sidebar-wrapper>.nav li>a p {
    -webkit-transform: translate3d(-25px, 0, 0);
    -moz-transform   : translate3d(-25px, 0, 0);
    -o-transform     : translate3d(-25px, 0, 0);
    -ms-transform    : translate3d(-25px, 0, 0);
    transform        : translate3d(-25px, 0, 0);
    opacity          : 0;
  }

  .AdminMAinControl .sidebar-mini .sidebar:hover {
    width: 260px;
  }

  .AdminMAinControl .sidebar-mini .sidebar:hover .logo a.logo-normal {
    opacity          : 1;
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform   : translate3d(0px, 0, 0);
    -o-transform     : translate3d(0px, 0, 0);
    -ms-transform    : translate3d(0px, 0, 0);
    transform        : translate3d(0px, 0, 0);
  }

  .AdminMAinControl .sidebar-mini .sidebar:hover .sidebar-wrapper {
    width: 260px;
  }

  .AdminMAinControl .sidebar-mini .sidebar:hover .sidebar-wrapper>.nav li>a p,
  .AdminMAinControl .sidebar-mini .sidebar:hover .sidebar-wrapper>.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
  .AdminMAinControl .sidebar-mini .sidebar:hover .sidebar-wrapper .user .user-info [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
  .AdminMAinControl .sidebar-mini .sidebar:hover .sidebar-wrapper .user .user-info>a>span {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform   : translate3d(0px, 0, 0);
    -o-transform     : translate3d(0px, 0, 0);
    -ms-transform    : translate3d(0px, 0, 0);
    transform        : translate3d(0px, 0, 0);
    opacity          : 1;
  }

  .AdminMAinControl .sidebar .nav .nav-item.active-pro {
    position: absolute;
    width   : 100%;
    bottom  : 13px;
    left    : 0;
  }
}

.AdminMAinControl .fixed-plugin .dropdown .dropdown-menu {
  border-radius: 10px;
}

.AdminMAinControl .fixed-plugin .dropdown .dropdown-menu li.adjustments-line {
  border-bottom: 1px solid #ddd;
}

.AdminMAinControl .fixed-plugin .dropdown .dropdown-menu li {
  padding: 5px 2px !important;
}

.AdminMAinControl .fixed-plugin .dropdown .dropdown-menu .adjustments-line .bootstrap-switch {
  position: absolute;
  right   : 10px !important;
}

.AdminMAinControl .fixed-plugin .dropdown .dropdown-menu .adjustments-line label {
  margin-bottom: .1rem !important;
}

.AdminMAinControl .fixed-plugin li>a,
.AdminMAinControl .fixed-plugin .badge {
  transition        : all .34s;
  -webkit-transition: all .34s;
  -moz-transition   : all .34s;
}

.AdminMAinControl .fixed-plugin {
  position     : fixed;
  top          : 115px;
  right        : 0;
  width        : 64px;
  background   : rgba(0, 0, 0, 0.3);
  z-index      : 3;
  border-radius: 8px 0 0 8px;
  text-align   : center;
}

.AdminMAinControl .fixed-plugin .fa-cog {
  color        : #FFFFFF;
  padding      : 10px;
  border-radius: 0 0 6px 6px;
  width        : auto;
}

.AdminMAinControl .fixed-plugin .dropdown-menu {
  right        : 80px;
  left         : auto;
  width        : 290px;
  border-radius: 0.1875rem;
  padding      : 0 10px;
}

.AdminMAinControl .fixed-plugin .dropdown-menu:after,
.AdminMAinControl .fixed-plugin .dropdown-menu:before {
  right      : 10px;
  margin-left: auto;
  left       : auto;
}

.AdminMAinControl .fixed-plugin .fa-circle-thin {
  color: #FFFFFF;
}

.AdminMAinControl .fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.AdminMAinControl .fixed-plugin .dropdown-menu>.active>a,
.AdminMAinControl .fixed-plugin .dropdown-menu>.active>a:hover,
.AdminMAinControl .fixed-plugin .dropdown-menu>.active>a:focus {
  color     : #777777;
  text-align: center;
}

.AdminMAinControl .fixed-plugin img {
  border-radius: 0;
  width        : 100%;
  height       : 100px;
  margin       : 0 auto;
}

.AdminMAinControl .fixed-plugin .dropdown-menu li>a:hover,
.AdminMAinControl .fixed-plugin .dropdown-menu li>a:focus {
  box-shadow: none;
}

.AdminMAinControl .fixed-plugin .badge {
  border       : 3px solid #FFFFFF;
  border-radius: 50%;
  cursor       : pointer;
  display      : inline-block;
  height       : 23px;
  margin-right : 5px;
  position     : relative;
  width        : 23px;
  padding      : 8px;
}

.AdminMAinControl .fixed-plugin .badge.active,
.AdminMAinControl .fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.AdminMAinControl .fixed-plugin .badge-black {
  background-color: #000;
}

.AdminMAinControl .fixed-plugin .badge-azure {
  background-color: #2CA8FF;
}

.AdminMAinControl .fixed-plugin .badge-green {
  background-color: #18ce0f;
}

.AdminMAinControl .fixed-plugin .badge-orange {
  background-color: #f96332;
}

.AdminMAinControl .fixed-plugin .badge-yellow {
  background-color: #FFB236;
}

.AdminMAinControl .fixed-plugin .badge-danger {
  background-color: #f44336;
}

.AdminMAinControl .fixed-plugin .badge-purple {
  background-color: #9368E9;
}

.AdminMAinControl .fixed-plugin .badge-white {
  background-color: rgba(200, 200, 200, 0.2);
}

.AdminMAinControl .fixed-plugin .badge-rose {
  background-color: #e91e63;
}

.AdminMAinControl .fixed-plugin h5 {
  font-size: 14px;
  margin   : 10px;
}

.AdminMAinControl .fixed-plugin .dropdown-menu li {
  display: block;
  padding: 18px 2px;
  width  : 25%;
  float  : left;
}

.AdminMAinControl .fixed-plugin li.adjustments-line,
.AdminMAinControl .fixed-plugin li.header-title,
.AdminMAinControl .fixed-plugin li.button-container {
  width     : 100%;
  height    : 50px;
  min-height: inherit;
}

.AdminMAinControl .fixed-plugin li.button-container {
  height: auto;
}

.AdminMAinControl .fixed-plugin li.button-container div {
  margin-bottom: 5px;
}

.AdminMAinControl .fixed-plugin .btn {
  position     : relative;
  padding      : 12px 30px;
  margin       : 0.3125rem 1px;
  font-size    : .75rem;
  border-radius: 0.2rem;
  transition   : box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change  : box-shadow, transform;
}

.AdminMAinControl .fixed-plugin .btn.btn-primary {
  color           : #fff;
  background-color: #9c27b0;
  border-color    : #9c27b0;
  box-shadow      : 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-primary:hover {
  color           : #fff;
  background-color: #9124a3;
  border-color    : #701c7e;
}

.AdminMAinControl .fixed-plugin .btn.btn-primary:focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary.focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary:hover {
  color           : #fff;
  background-color: #9124a3;
  border-color    : #701c7e;
}

.AdminMAinControl .fixed-plugin .btn.btn-primary:active,
.AdminMAinControl .fixed-plugin .btn.btn-primary.active,
.AdminMAinControl .open>.fixed-plugin .btn.btn-primary.dropdown-toggle,
.AdminMAinControl .show>.fixed-plugin .btn.btn-primary.dropdown-toggle {
  color           : #fff;
  background-color: #9124a3;
  border-color    : #701c7e;
  box-shadow      : 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-primary:active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-primary:active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary:active.focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary.active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-primary.active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary.active.focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-primary.dropdown-toggle:hover,
.AdminMAinControl .open>.fixed-plugin .btn.btn-primary.dropdown-toggle:focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-primary.dropdown-toggle.focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-primary.dropdown-toggle:hover,
.AdminMAinControl .show>.fixed-plugin .btn.btn-primary.dropdown-toggle:focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-primary.dropdown-toggle.focus {
  color           : #fff;
  background-color: #9124a3;
  border-color    : #3f1048;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-primary.dropdown-toggle.bmd-btn-icon {
  color           : inherit;
  background-color: #9c27b0;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #9124a3;
}

.AdminMAinControl .fixed-plugin .btn.btn-primary.disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary.disabled.focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary:disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary:disabled.focus {
  background-color: #9c27b0;
  border-color    : #9c27b0;
}

.AdminMAinControl .fixed-plugin .btn.btn-primary.disabled:hover,
.AdminMAinControl .fixed-plugin .btn.btn-primary:disabled:hover {
  background-color: #9c27b0;
  border-color    : #9c27b0;
}

.AdminMAinControl .fixed-plugin .btn.btn-primary:focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary:active,
.AdminMAinControl .fixed-plugin .btn.btn-primary:hover {
  box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}

.AdminMAinControl .fixed-plugin .btn.btn-primary.btn-link {
  background-color: transparent;
  color           : #9c27b0;
  box-shadow      : none;
}

.AdminMAinControl .fixed-plugin .btn.btn-primary.btn-link:hover,
.AdminMAinControl .fixed-plugin .btn.btn-primary.btn-link:focus,
.AdminMAinControl .fixed-plugin .btn.btn-primary.btn-link:active {
  background-color: transparent;
  color           : #9c27b0;
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary {
  color           : #333333;
  background-color: #fafafa;
  border-color    : #ccc;
  box-shadow      : 0 2px 2px 0 rgba(250, 250, 250, 0.14), 0 3px 1px -2px rgba(250, 250, 250, 0.2), 0 1px 5px 0 rgba(250, 250, 250, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary:hover {
  color           : #333333;
  background-color: #f2f2f2;
  border-color    : #adadad;
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary:focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary.focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary:hover {
  color           : #333333;
  background-color: #f2f2f2;
  border-color    : #adadad;
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary:active,
.AdminMAinControl .fixed-plugin .btn.btn-secondary.active,
.AdminMAinControl .open>.fixed-plugin .btn.btn-secondary.dropdown-toggle,
.AdminMAinControl .show>.fixed-plugin .btn.btn-secondary.dropdown-toggle {
  color           : #333333;
  background-color: #f2f2f2;
  border-color    : #adadad;
  box-shadow      : 0 2px 2px 0 rgba(250, 250, 250, 0.14), 0 3px 1px -2px rgba(250, 250, 250, 0.2), 0 1px 5px 0 rgba(250, 250, 250, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary:active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-secondary:active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary:active.focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary.active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-secondary.active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary.active.focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-secondary.dropdown-toggle:hover,
.AdminMAinControl .open>.fixed-plugin .btn.btn-secondary.dropdown-toggle:focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-secondary.dropdown-toggle.focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-secondary.dropdown-toggle:hover,
.AdminMAinControl .show>.fixed-plugin .btn.btn-secondary.dropdown-toggle:focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-secondary.dropdown-toggle.focus {
  color           : #333333;
  background-color: #f2f2f2;
  border-color    : #8c8c8c;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-secondary.dropdown-toggle.bmd-btn-icon {
  color           : inherit;
  background-color: #fafafa;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f2f2f2;
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary.disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary.disabled.focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary:disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary:disabled.focus {
  background-color: #fafafa;
  border-color    : #ccc;
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary.disabled:hover,
.AdminMAinControl .fixed-plugin .btn.btn-secondary:disabled:hover {
  background-color: #fafafa;
  border-color    : #ccc;
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary:focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary:active,
.AdminMAinControl .fixed-plugin .btn.btn-secondary:hover {
  box-shadow: 0 14px 26px -12px rgba(250, 250, 250, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(250, 250, 250, 0.2);
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary.btn-link {
  background-color: transparent;
  color           : #fafafa;
  box-shadow      : none;
}

.AdminMAinControl .fixed-plugin .btn.btn-secondary.btn-link:hover,
.AdminMAinControl .fixed-plugin .btn.btn-secondary.btn-link:focus,
.AdminMAinControl .fixed-plugin .btn.btn-secondary.btn-link:active {
  background-color: transparent;
  color           : #fafafa;
}

.AdminMAinControl .fixed-plugin .btn.btn-info {
  color           : #fff;
  background-color: #00bcd4;
  border-color    : #00bcd4;
  box-shadow      : 0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-info:hover {
  color           : #fff;
  background-color: #00aec5;
  border-color    : #008697;
}

.AdminMAinControl .fixed-plugin .btn.btn-info:focus,
.AdminMAinControl .fixed-plugin .btn.btn-info.focus,
.AdminMAinControl .fixed-plugin .btn.btn-info:hover {
  color           : #fff;
  background-color: #00aec5;
  border-color    : #008697;
}

.AdminMAinControl .fixed-plugin .btn.btn-info:active,
.AdminMAinControl .fixed-plugin .btn.btn-info.active,
.AdminMAinControl .open>.fixed-plugin .btn.btn-info.dropdown-toggle,
.AdminMAinControl .show>.fixed-plugin .btn.btn-info.dropdown-toggle {
  color           : #fff;
  background-color: #00aec5;
  border-color    : #008697;
  box-shadow      : 0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-info:active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-info:active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-info:active.focus,
.AdminMAinControl .fixed-plugin .btn.btn-info.active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-info.active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-info.active.focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-info.dropdown-toggle:hover,
.AdminMAinControl .open>.fixed-plugin .btn.btn-info.dropdown-toggle:focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-info.dropdown-toggle.focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-info.dropdown-toggle:hover,
.AdminMAinControl .show>.fixed-plugin .btn.btn-info.dropdown-toggle:focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-info.dropdown-toggle.focus {
  color           : #fff;
  background-color: #00aec5;
  border-color    : #004b55;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-info.dropdown-toggle.bmd-btn-icon {
  color           : inherit;
  background-color: #00bcd4;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #00aec5;
}

.AdminMAinControl .fixed-plugin .btn.btn-info.disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-info.disabled.focus,
.AdminMAinControl .fixed-plugin .btn.btn-info:disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-info:disabled.focus {
  background-color: #00bcd4;
  border-color    : #00bcd4;
}

.AdminMAinControl .fixed-plugin .btn.btn-info.disabled:hover,
.AdminMAinControl .fixed-plugin .btn.btn-info:disabled:hover {
  background-color: #00bcd4;
  border-color    : #00bcd4;
}

.AdminMAinControl .fixed-plugin .btn.btn-info:focus,
.AdminMAinControl .fixed-plugin .btn.btn-info:active,
.AdminMAinControl .fixed-plugin .btn.btn-info:hover {
  box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2);
}

.AdminMAinControl .fixed-plugin .btn.btn-info.btn-link {
  background-color: transparent;
  color           : #00bcd4;
  box-shadow      : none;
}

.AdminMAinControl .fixed-plugin .btn.btn-info.btn-link:hover,
.AdminMAinControl .fixed-plugin .btn.btn-info.btn-link:focus,
.AdminMAinControl .fixed-plugin .btn.btn-info.btn-link:active {
  background-color: transparent;
  color           : #00bcd4;
}

.AdminMAinControl .fixed-plugin .btn.btn-success {
  color           : #fff;
  background-color: #4caf50;
  border-color    : #4caf50;
  box-shadow      : 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-success:hover {
  color           : #fff;
  background-color: #47a44b;
  border-color    : #39843c;
}

.AdminMAinControl .fixed-plugin .btn.btn-success:focus,
.AdminMAinControl .fixed-plugin .btn.btn-success.focus,
.AdminMAinControl .fixed-plugin .btn.btn-success:hover {
  color           : #fff;
  background-color: #47a44b;
  border-color    : #39843c;
}

.AdminMAinControl .fixed-plugin .btn.btn-success:active,
.AdminMAinControl .fixed-plugin .btn.btn-success.active,
.AdminMAinControl .open>.fixed-plugin .btn.btn-success.dropdown-toggle,
.AdminMAinControl .show>.fixed-plugin .btn.btn-success.dropdown-toggle {
  color           : #fff;
  background-color: #47a44b;
  border-color    : #39843c;
  box-shadow      : 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-success:active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-success:active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-success:active.focus,
.AdminMAinControl .fixed-plugin .btn.btn-success.active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-success.active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-success.active.focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-success.dropdown-toggle:hover,
.AdminMAinControl .open>.fixed-plugin .btn.btn-success.dropdown-toggle:focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-success.dropdown-toggle.focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-success.dropdown-toggle:hover,
.AdminMAinControl .show>.fixed-plugin .btn.btn-success.dropdown-toggle:focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-success.dropdown-toggle.focus {
  color           : #fff;
  background-color: #47a44b;
  border-color    : #255627;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-success.dropdown-toggle.bmd-btn-icon {
  color           : inherit;
  background-color: #4caf50;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #47a44b;
}

.AdminMAinControl .fixed-plugin .btn.btn-success.disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-success.disabled.focus,
.AdminMAinControl .fixed-plugin .btn.btn-success:disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-success:disabled.focus {
  background-color: #4caf50;
  border-color    : #4caf50;
}

.AdminMAinControl .fixed-plugin .btn.btn-success.disabled:hover,
.AdminMAinControl .fixed-plugin .btn.btn-success:disabled:hover {
  background-color: #4caf50;
  border-color    : #4caf50;
}

.AdminMAinControl .fixed-plugin .btn.btn-success:focus,
.AdminMAinControl .fixed-plugin .btn.btn-success:active,
.AdminMAinControl .fixed-plugin .btn.btn-success:hover {
  box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
}

.AdminMAinControl .fixed-plugin .btn.btn-success.btn-link {
  background-color: transparent;
  color           : #4caf50;
  box-shadow      : none;
}

.AdminMAinControl .fixed-plugin .btn.btn-success.btn-link:hover,
.AdminMAinControl .fixed-plugin .btn.btn-success.btn-link:focus,
.AdminMAinControl .fixed-plugin .btn.btn-success.btn-link:active {
  background-color: transparent;
  color           : #4caf50;
}

.AdminMAinControl .fixed-plugin .btn.btn-warning {
  color           : #fff;
  background-color: #ff9800;
  border-color    : #ff9800;
  box-shadow      : 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-warning:hover {
  color           : #fff;
  background-color: #f08f00;
  border-color    : #c27400;
}

.AdminMAinControl .fixed-plugin .btn.btn-warning:focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning.focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning:hover {
  color           : #fff;
  background-color: #f08f00;
  border-color    : #c27400;
}

.AdminMAinControl .fixed-plugin .btn.btn-warning:active,
.AdminMAinControl .fixed-plugin .btn.btn-warning.active,
.AdminMAinControl .open>.fixed-plugin .btn.btn-warning.dropdown-toggle,
.AdminMAinControl .show>.fixed-plugin .btn.btn-warning.dropdown-toggle {
  color           : #fff;
  background-color: #f08f00;
  border-color    : #c27400;
  box-shadow      : 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-warning:active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-warning:active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning:active.focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning.active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-warning.active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning.active.focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-warning.dropdown-toggle:hover,
.AdminMAinControl .open>.fixed-plugin .btn.btn-warning.dropdown-toggle:focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-warning.dropdown-toggle.focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-warning.dropdown-toggle:hover,
.AdminMAinControl .show>.fixed-plugin .btn.btn-warning.dropdown-toggle:focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-warning.dropdown-toggle.focus {
  color           : #fff;
  background-color: #f08f00;
  border-color    : #804c00;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-warning.dropdown-toggle.bmd-btn-icon {
  color           : inherit;
  background-color: #ff9800;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f08f00;
}

.AdminMAinControl .fixed-plugin .btn.btn-warning.disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning.disabled.focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning:disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning:disabled.focus {
  background-color: #ff9800;
  border-color    : #ff9800;
}

.AdminMAinControl .fixed-plugin .btn.btn-warning.disabled:hover,
.AdminMAinControl .fixed-plugin .btn.btn-warning:disabled:hover {
  background-color: #ff9800;
  border-color    : #ff9800;
}

.AdminMAinControl .fixed-plugin .btn.btn-warning:focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning:active,
.AdminMAinControl .fixed-plugin .btn.btn-warning:hover {
  box-shadow: 0 14px 26px -12px rgba(255, 152, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2);
}

.AdminMAinControl .fixed-plugin .btn.btn-warning.btn-link {
  background-color: transparent;
  color           : #ff9800;
  box-shadow      : none;
}

.AdminMAinControl .fixed-plugin .btn.btn-warning.btn-link:hover,
.AdminMAinControl .fixed-plugin .btn.btn-warning.btn-link:focus,
.AdminMAinControl .fixed-plugin .btn.btn-warning.btn-link:active {
  background-color: transparent;
  color           : #ff9800;
}

.AdminMAinControl .fixed-plugin .btn.btn-danger {
  color           : #fff;
  background-color: #f44336;
  border-color    : #f44336;
  box-shadow      : 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-danger:hover {
  color           : #fff;
  background-color: #f33527;
  border-color    : #e11b0c;
}

.AdminMAinControl .fixed-plugin .btn.btn-danger:focus,
.AdminMAinControl .fixed-plugin .btn.btn-danger.focus,
.AdminMAinControl .fixed-plugin .btn.btn-danger:hover {
  color           : #fff;
  background-color: #f33527;
  border-color    : #e11b0c;
}

.AdminMAinControl .fixed-plugin .btn.btn-danger:active,
.AdminMAinControl .fixed-plugin .btn.btn-danger.active,
.AdminMAinControl .open>.fixed-plugin .btn.btn-danger.dropdown-toggle,
.AdminMAinControl .show>.fixed-plugin .btn.btn-danger.dropdown-toggle {
  color           : #fff;
  background-color: #f33527;
  border-color    : #e11b0c;
  box-shadow      : 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}

.AdminMAinControl .fixed-plugin .btn.btn-danger:active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-danger:active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-danger:active.focus,
.AdminMAinControl .fixed-plugin .btn.btn-danger.active:hover,
.AdminMAinControl .fixed-plugin .btn.btn-danger.active:focus,
.AdminMAinControl .fixed-plugin .btn.btn-danger.active.focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-danger.dropdown-toggle:hover,
.AdminMAinControl .open>.fixed-plugin .btn.btn-danger.dropdown-toggle:focus,
.AdminMAinControl .open>.fixed-plugin .btn.btn-danger.dropdown-toggle.focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-danger.dropdown-toggle:hover,
.AdminMAinControl .show>.fixed-plugin .btn.btn-danger.dropdown-toggle:focus,
.AdminMAinControl .show>.fixed-plugin .btn.btn-danger.dropdown-toggle.focus {
  color           : #fff;
  background-color: #f33527;
  border-color    : #a21309;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-danger.dropdown-toggle.bmd-btn-icon {
  color           : inherit;
  background-color: #f44336;
}

.AdminMAinControl .open>.fixed-plugin .btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f33527;
}

.AdminMAinControl .fixed-plugin .btn.btn-danger.disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-danger.disabled.focus,
.AdminMAinControl .fixed-plugin .btn.btn-danger:disabled:focus,
.AdminMAinControl .fixed-plugin .btn.btn-danger:disabled.focus {
  background-color: #f44336;
  border-color    : #f44336;
}

.AdminMAinControl .fixed-plugin .btn.btn-danger.disabled:hover,
.AdminMAinControl .fixed-plugin .btn.btn-danger:disabled:hover {
  background-color: #f44336;
  border-color    : #f44336;
}









@media all and (max-width: 991px) {

  .AdminMAinControl .navbar.navbar-absolute {
    position: fixed;
  }


  .AdminMAinControl .sidebar.rightAc {
    right  : 260px;
    z-index: 99999;
  }

  .AdminMAinControl .close-layer.clactive {
    display: block;
    opacity: 1;
  }

  .AdminMAinControl .close-layer {
    display           : none;
    height            : 100%;
    width             : 100%;
    position          : absolute;
    opacity           : 0;
    top               : 0;
    left              : auto;
    background        : rgba(0, 0, 0, 0.35);
    content           : "";
    z-index           : 9999;
    overflow-x        : hidden;
    -webkit-transition: all 370ms ease-in;
    -moz-transition   : all 370ms ease-in;
    -o-transition     : all 370ms ease-in;
    -ms-transition    : all 370ms ease-in;
    transition        : all 370ms ease-in;
  }



  [class*="navbar-expand-"]>.AdminMAinControl .container,
  [class*="navbar-expand-"]>.AdminMAinControl .container-fluid {
    padding-left : 15px;
    padding-right: 15px;
  }



  .AdminMAinControl .navbar-expand-lg>.container-fluid {
    display        : flex;
    flex-wrap      : wrap;
    align-items    : center;
    justify-content: flex-end;
  }



  .AdminMAinControl .navbar .navbar-collapse .navbar-nav>li.button-container {
    padding: 15px;
  }

  .AdminMAinControl .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: -webkit-fill-available !important;
  }

  .AdminMAinControl .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) .dropdown-menu.show {
    min-width: auto;
    left     : auto;
  }

  .AdminMAinControl .carousel .card .card-body {
    max-width : 340px;
    margin    : 0 auto;
    min-height: 400px;
  }

  /* .AdminMAinControl .navbar-collapse {
    position          : fixed;
    display           : block;
    top               : 0px;
    height            : 100vh;
    width             : 230px;
    right             : 0;
    margin-right      : 0 !important;
    z-index           : 1032;
    visibility        : visible;
    background-color  : #999;
    overflow-y        : visible;
    border-top        : none;
    text-align        : left;
    padding-right     : 0;
    padding-left      : 0;
    max-height        : none !important;
    -webkit-transform : translate3d(230px, 0, 0);
    -moz-transform    : translate3d(230px, 0, 0);
    -o-transform      : translate3d(230px, 0, 0);
    -ms-transform     : translate3d(230px, 0, 0);
    transform         : translate3d(230px, 0, 0);
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition   : all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition     : all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition    : all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition        : all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  } */
  /* 
  .AdminMAinControl .navbar-collapse::after {
    top             : 0;
    left            : 0;
    height          : 100%;
    width           : 100%;
    position        : absolute;
    background-color: #fff;
    display         : block;
    content         : "";
    z-index         : 1;
  } */

  .AdminMAinControl .navbar-collapse .dropdown-toggle:after {
    position  : absolute;
    right     : 16px;
    margin-top: 8px;
  }

  .AdminMAinControl .navbar-collapse .navbar-nav {
    position: relative;
    z-index : 3;
  }

  .AdminMAinControl .navbar-collapse .navbar-nav .nav-item .nav-link {
    color : #3C4858;
    margin: 5px 15px;
  }

  .AdminMAinControl .navbar-collapse .navbar-nav .nav-item.button-container .nav-link {
    margin: 15px;
  }

  /* 
  .AdminMAinControl .navbar-collapse .navbar-nav .nav-item:after {
    width      : calc(100% - 30px);
    content    : "";
    display    : block;
    height     : 1px;
    margin-left: 15px;
  } */

  .AdminMAinControl .navbar-collapse .navbar-nav .nav-item:last-child:after {
    display: none;
  }

  .AdminMAinControl .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform   : translate3d(0px, 0, 0);
    -o-transform     : translate3d(0px, 0, 0);
    -ms-transform    : translate3d(0px, 0, 0);
    transform        : translate3d(0px, 0, 0);
  }

  .AdminMAinControl .nav-open .navbar-translate {
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform   : translate3d(-230px, 0, 0);
    -o-transform     : translate3d(-230px, 0, 0);
    -ms-transform    : translate3d(-230px, 0, 0);
    transform        : translate3d(-230px, 0, 0);
  }

  .AdminMAinControl .navbar .navbar-translate {
    width             : 100%;
    position          : relative;
    display           : flex;
    -ms-flex-pack     : justify !important;
    justify-content   : space-between !important;
    -ms-flex-align    : center;
    align-items       : center;
    -webkit-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition   : transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition     : transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition    : transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition        : transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .AdminMAinControl .navbar .dropdown.show .dropdown-menu {
    display: block;
  }

  .AdminMAinControl .navbar .dropdown .dropdown-menu {
    display: none;
  }

  .AdminMAinControl .navbar .dropdown-menu .dropdown-item {
    margin-left : 1.5rem;
    margin-right: 1.5rem;
  }

  .AdminMAinControl .navbar .dropdown.show .dropdown-menu,
  .AdminMAinControl .navbar .dropdown .dropdown-menu {
    background-color  : transparent;
    border            : 0;
    padding-bottom    : 15px;
    transition        : none;
    -webkit-box-shadow: none;
    box-shadow        : none;
    transform         : none !important;
    width             : auto;
    margin-bottom     : 15px;
    padding-top       : 0;
    height            : 300px;
    animation         : none;
    opacity           : 1;
    overflow-y        : scroll;
  }

  .AdminMAinControl .navbar.navbar-transparent .navbar-toggler .navbar-toggler-icon {
    background-color: #fff;
  }

  .AdminMAinControl #bodyClick {
    height    : 100%;
    width     : 100%;
    position  : fixed;
    opacity   : 0;
    top       : 0;
    left      : auto;
    right     : 230px;
    content   : "";
    z-index   : 1029;
    overflow-x: hidden;
  }

  .AdminMAinControl #navbar .navbar-collapse,
  .AdminMAinControl #navigation .navbar-collapse {
    display: none !important;
  }

  .AdminMAinControl .dropdown-menu.show .dropdown-item.open+.dropdown-menu.show {
    right: 101% !important;
  }

  .AdminMAinControl .dropdown-menu.show .dropdown-item.open+.dropdown-menu.show .dropdown-item.open+.dropdown-menu,
  .AdminMAinControl .dropdown-menu.show .dropdown-item.open+.dropdown-menu.show .dropdown-item.open+.dropdown-menu.show {
    left: -165px !important;
  }
}

@media all and (min-width: 991px) {
  .AdminMAinControl .navbar .navbar-nav {
    align-items: center;
  }

  .AdminMAinControl .navbar .navbar-nav .button-container {
    margin-left: 0.1875px;
  }

  .AdminMAinControl .sidebar .navbar-form {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .AdminMAinControl .presentation-page .section-components .components-macbook {
    max-width : 850px !important;
    max-height: 480px !important;
    margin-top: 12vh;
    left      : -12px;
  }

  .AdminMAinControl .presentation-page .section-components .coloured-card-img,
  .AdminMAinControl .presentation-page .section-components .table-img {
    display: none;
  }

  .AdminMAinControl .presentation-page .section-components .social-img {
    left: 47%;
    top : 37%;
  }

  .AdminMAinControl .presentation-page .section-components .pin-btn-img {
    top: 54%;
  }

  .AdminMAinControl .presentation-page .section-components .share-btn-img {
    top: 12%;
  }

  .AdminMAinControl .presentation-page .section-components .coloured-card-btn-img {
    top : -2%;
    left: 65%;
  }

  .AdminMAinControl .presentation-page .section-content .area-img {
    max-width : 130px;
    max-height: 170px;
  }

  .AdminMAinControl .presentation-page .section-content .info-img {
    max-width : 170px;
    max-height: 120px;
  }
}

@media screen and (max-width: 767px) {
  .AdminMAinControl .presentation-page .section-components .components-macbook {
    max-width : 350px !important;
    max-height: 250px !important;
    margin-top: 12vh;
    left      : -12px;
  }

  .AdminMAinControl .presentation-page .section-components .coloured-card-img,
  .AdminMAinControl .presentation-page .section-components .table-img {
    display: none;
  }

  .AdminMAinControl .presentation-page .section-components .social-img {
    left: -7%;
    top : 37%;
  }

  .AdminMAinControl .presentation-page .section-components .pin-btn-img {
    top: 54%;
  }

  .AdminMAinControl .presentation-page .section-components .share-btn-img {
    top: 7%;
  }

  .AdminMAinControl .presentation-page .section-components .coloured-card-btn-img {
    top: -2%;
  }

  .AdminMAinControl .login-page .container {
    padding-top: 100px !important;
  }

  .AdminMAinControl .presentation-page #cd-vertical-nav,
  .AdminMAinControl .index-page #cd-vertical-nav,
  .AdminMAinControl .section-page #cd-vertical-nav {
    display: none;
  }

  .AdminMAinControl .index-page .cd-section .tim-typo .tim-note {
    width: 60px;
  }
}

@media screen and (max-width: 400px) {
  .AdminMAinControl .cd-vertical-nav {
    display: none !important;
  }
}















/*          Changes for small display      */
@media (max-width: 991px) {

  .AdminMAinControl .nav-open .menu-on-left .main-panel {
    position: initial;
  }


  .AdminMAinControl .nav-open .menu-on-left .main-panel,
  .AdminMAinControl .nav-open .menu-on-left .wrapper-full-page,
  .AdminMAinControl .nav-open .menu-on-left .navbar-fixed>div {
    -webkit-transform: translate3d(260px, 0, 0);
    -moz-transform   : translate3d(260px, 0, 0);
    -o-transform     : translate3d(260px, 0, 0);
    -ms-transform    : translate3d(260px, 0, 0);
    transform        : translate3d(260px, 0, 0);
  }

  .AdminMAinControl .menu-on-left .sidebar,
  .AdminMAinControl .menu-on-left .off-canvas-sidebar {
    left             : 0;
    right            : auto;
    -webkit-transform: translate3d(-260px, 0, 0);
    -moz-transform   : translate3d(-260px, 0, 0);
    -o-transform     : translate3d(-260px, 0, 0);
    -ms-transform    : translate3d(-260px, 0, 0);
    transform        : translate3d(-260px, 0, 0);
  }

  .AdminMAinControl .menu-on-left .close-layer {
    left : auto;
    right: 0;
  }

  .AdminMAinControl .timeline:before {
    left: 5%;
  }

  .AdminMAinControl .timeline>li>.timeline-badge {
    left: 5%;
  }

  .AdminMAinControl .timeline>li>.timeline-panel {
    float: right;
    width: 86%;
  }

  .AdminMAinControl .timeline>li>.timeline-panel:before {
    border-left-width : 0;
    border-right-width: 15px;
    left              : -15px;
    right             : auto;
  }

  .AdminMAinControl .timeline>li>.timeline-panel:after {
    border-left-width : 0;
    border-right-width: 14px;
    left              : -14px;
    right             : auto;
  }

  .AdminMAinControl .nav-mobile-menu .dropdown .dropdown-menu {
    display         : none;
    position        : static !important;
    background-color: transparent;
    width           : auto;
    float           : none;
    box-shadow      : none;
  }

  .AdminMAinControl .nav-mobile-menu .dropdown .dropdown-menu.showing {
    animation         : initial;
    animation-duration: 0s;
  }

  .AdminMAinControl .nav-mobile-menu .dropdown .dropdown-menu.hiding {
    transform: none;
    opacity  : 1;
  }

  .AdminMAinControl .nav-mobile-menu .dropdown.show .dropdown-menu {
    display: block;
  }

  .AdminMAinControl .nav-mobile-menu li.active>a {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .AdminMAinControl .navbar-minimize {
    display: none;
  }

  .AdminMAinControl .card .form-horizontal .label-on-left,
  .AdminMAinControl .card .form-horizontal .label-on-right {
    padding-left: 15px;
    padding-top : 8px;
  }

  .AdminMAinControl .card .form-horizontal .form-group {
    margin-top: 0px;
  }

  .AdminMAinControl .card .form-horizontal .checkbox-radios {
    padding-bottom: 15px;
  }

  .AdminMAinControl .card .form-horizontal .checkbox-radios .checkbox:first-child,
  .AdminMAinControl .card .form-horizontal .checkbox-radios .radio:first-child {
    margin-top: 0;
  }

  .AdminMAinControl .card .form-horizontal .checkbox-inline {
    margin-top: 0;
  }

  .AdminMAinControl .sidebar {
    display   : none;
    box-shadow: none;
  }

  .AdminMAinControl .sidebar .sidebar-wrapper {
    padding-bottom: 60px;
  }

  .AdminMAinControl .sidebar .nav-mobile-menu {
    margin-top: 0;
  }

  .AdminMAinControl .sidebar .nav-mobile-menu .notification {
    float       : left;
    line-height : 30px;
    margin-right: 8px;
  }

  .AdminMAinControl .sidebar .nav-mobile-menu .open .dropdown-menu {
    position          : static;
    float             : none;
    width             : auto;
    margin-top        : 0;
    background-color  : transparent;
    border            : 0;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }

  .AdminMAinControl .main-panel {
    width: 100%;
  }

  .AdminMAinControl .navbar-transparent {
    padding-top     : 15px;
    background-color: rgba(0, 0, 0, 0.45);
  }


  .AdminMAinControl .nav-open .main-panel,
  .AdminMAinControl .nav-open .wrapper-full-page,
  .AdminMAinControl .nav-open .navbar .container .navbar-toggler,
  .AdminMAinControl .nav-open .navbar .container .navbar-wrapper,
  .AdminMAinControl .nav-open .navbar .container {
    left             : 0;
    -webkit-transform: translate3d(-260px, 0, 0);
    -moz-transform   : translate3d(-260px, 0, 0);
    -o-transform     : translate3d(-260px, 0, 0);
    -ms-transform    : translate3d(-260px, 0, 0);
    transform        : translate3d(-260px, 0, 0);
  }

  .AdminMAinControl .nav-open .sidebar {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }

  .AdminMAinControl .nav-open .off-canvas-sidebar .navbar-collapse,
  .AdminMAinControl .nav-open .sidebar {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform   : translate3d(0, 0, 0);
    -o-transform     : translate3d(0, 0, 0);
    -ms-transform    : translate3d(0, 0, 0);
    transform        : translate3d(0, 0, 0);
  }

  .AdminMAinControl .wrapper-full-page,
  .AdminMAinControl .navbar .container .navbar-toggler,
  .AdminMAinControl .navbar .container .navbar-wrapper,
  .AdminMAinControl .navbar .container {
    -webkit-transform : translate3d(0px, 0, 0);
    -moz-transform    : translate3d(0px, 0, 0);
    -o-transform      : translate3d(0px, 0, 0);
    -ms-transform     : translate3d(0px, 0, 0);
    transform         : translate3d(0px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition   : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition     : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition    : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition        : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left              : 0;
  }

  .AdminMAinControl .off-canvas-sidebar .navbar .container {
    transform: none;
  }

  .AdminMAinControl .main-panel,
  .AdminMAinControl .navbar-collapse {
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition   : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition     : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition    : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition        : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .AdminMAinControl .navbar .navbar-collapse.collapse,
  .AdminMAinControl .navbar .navbar-collapse.collapse.in,
  .AdminMAinControl .navbar .navbar-collapse.collapsing {
    display       : none !important;
    flex-direction: row;
  }

  .AdminMAinControl .off-canvas-sidebar .navbar .navbar-collapse.collapse,
  .AdminMAinControl .off-canvas-sidebar .navbar .navbar-collapse.collapse.in,
  .AdminMAinControl .off-canvas-sidebar .navbar .navbar-collapse.collapsing {
    display: block !important;
  }

  .AdminMAinControl .navbar-nav>li {
    float   : none;
    position: relative;
    display : block;
  }

  .AdminMAinControl .off-canvas-sidebar nav .navbar-collapse {
    margin: 0;
  }

  .AdminMAinControl .off-canvas-sidebar nav .navbar-collapse>ul {
    margin-top: 19px;
  }

  .AdminMAinControl .sidebar,
  .AdminMAinControl .off-canvas-sidebar nav .navbar-collapse {
    position          : fixed;
    display           : block;
    top               : 0;
    height            : 100vh;
    width             : 260px;
    right             : 0;
    left              : auto;
    z-index           : 1032;
    visibility        : visible;
    background-color  : #9A9A9A;
    overflow-y        : visible;
    border-top        : none;
    text-align        : left;
    padding-right     : 0px;
    padding-left      : 0;
    -webkit-transform : translate3d(260px, 0, 0);
    -moz-transform    : translate3d(260px, 0, 0);
    -o-transform      : translate3d(260px, 0, 0);
    -ms-transform     : translate3d(260px, 0, 0);
    transform         : translate3d(260px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition   : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition     : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition    : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition        : all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .AdminMAinControl .sidebar>ul,
  .AdminMAinControl .off-canvas-sidebar nav .navbar-collapse>ul {
    position: relative;
    z-index : 4;
    width   : 100%;
  }

  .AdminMAinControl .sidebar::before,
  .AdminMAinControl .off-canvas-sidebar nav .navbar-collapse::before {
    top             : 0;
    left            : 0;
    height          : 100%;
    width           : 100%;
    position        : absolute;
    background-color: #f8f8f8;
    display         : block;
    content         : "";
    z-index         : 1;
  }



  .AdminMAinControl .sidebar .logo,
  .AdminMAinControl .off-canvas-sidebar nav .navbar-collapse .logo {
    position: relative;
    z-index : 4;
  }

  .AdminMAinControl .sidebar .navbar-form,
  .AdminMAinControl .off-canvas-sidebar nav .navbar-collapse .navbar-form {
    margin        : 10px 0px;
    float         : none !important;
    padding-top   : 1px;
    padding-bottom: 1px;
    position      : relative;
  }

  .AdminMAinControl .sidebar .table-responsive,
  .AdminMAinControl .off-canvas-sidebar nav .navbar-collapse .table-responsive {
    width                     : 100%;
    margin-bottom             : 15px;
    overflow-x                : scroll;
    overflow-y                : hidden;
    -ms-overflow-style        : -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }

  .AdminMAinControl .form-group.form-search .form-control {
    font-size: 1.7em;
    height   : 37px;
    width    : 78%;
  }

  .AdminMAinControl .navbar-form .btn {
    position: absolute;
    top     : -5px;
    right   : -50px;
  }


  .AdminMAinControl .close-layer.visible {
    opacity: 1;
  }

  .AdminMAinControl .navbar-toggler .icon-bar {
    display      : block;
    position     : relative;
    background   : #555 !important;
    width        : 24px;
    height       : 2px;
    border-radius: 1px;
    margin       : 0 auto;
  }

  .AdminMAinControl .navbar-header .navbar-toggler {
    padding   : 15px;
    margin-top: 4px;
    width     : 40px;
    height    : 40px;
  }

  .AdminMAinControl .bar1,
  .AdminMAinControl .bar2,
  .AdminMAinControl .bar3 {
    outline: 1px solid transparent;
  }

  @keyframes topbar-x {
    0% {
      top      : 0px;
      transform: rotate(0deg);
    }

    45% {
      top      : 6px;
      transform: rotate(145deg);
    }

    75% {
      transform: rotate(130deg);
    }

    100% {
      transform: rotate(135deg);
    }
  }

  @-webkit-keyframes topbar-x {
    0% {
      top              : 0px;
      -webkit-transform: rotate(0deg);
    }

    45% {
      top              : 6px;
      -webkit-transform: rotate(145deg);
    }

    75% {
      -webkit-transform: rotate(130deg);
    }

    100% {
      -webkit-transform: rotate(135deg);
    }
  }

  @-moz-keyframes topbar-x {
    0% {
      top           : 0px;
      -moz-transform: rotate(0deg);
    }

    45% {
      top           : 6px;
      -moz-transform: rotate(145deg);
    }

    75% {
      -moz-transform: rotate(130deg);
    }

    100% {
      -moz-transform: rotate(135deg);
    }
  }

  @keyframes topbar-back {
    0% {
      top      : 6px;
      transform: rotate(135deg);
    }

    45% {
      transform: rotate(-10deg);
    }

    75% {
      transform: rotate(5deg);
    }

    100% {
      top      : 0px;
      transform: rotate(0);
    }
  }

  @-webkit-keyframes topbar-back {
    0% {
      top              : 6px;
      -webkit-transform: rotate(135deg);
    }

    45% {
      -webkit-transform: rotate(-10deg);
    }

    75% {
      -webkit-transform: rotate(5deg);
    }

    100% {
      top              : 0px;
      -webkit-transform: rotate(0);
    }
  }

  @-moz-keyframes topbar-back {
    0% {
      top           : 6px;
      -moz-transform: rotate(135deg);
    }

    45% {
      -moz-transform: rotate(-10deg);
    }

    75% {
      -moz-transform: rotate(5deg);
    }

    100% {
      top           : 0px;
      -moz-transform: rotate(0);
    }
  }

  @keyframes bottombar-x {
    0% {
      bottom   : 0px;
      transform: rotate(0deg);
    }

    45% {
      bottom   : 6px;
      transform: rotate(-145deg);
    }

    75% {
      transform: rotate(-130deg);
    }

    100% {
      transform: rotate(-135deg);
    }
  }

  @-webkit-keyframes bottombar-x {
    0% {
      bottom           : 0px;
      -webkit-transform: rotate(0deg);
    }

    45% {
      bottom           : 6px;
      -webkit-transform: rotate(-145deg);
    }

    75% {
      -webkit-transform: rotate(-130deg);
    }

    100% {
      -webkit-transform: rotate(-135deg);
    }
  }

  @-moz-keyframes bottombar-x {
    0% {
      bottom        : 0px;
      -moz-transform: rotate(0deg);
    }

    45% {
      bottom        : 6px;
      -moz-transform: rotate(-145deg);
    }

    75% {
      -moz-transform: rotate(-130deg);
    }

    100% {
      -moz-transform: rotate(-135deg);
    }
  }

  @keyframes bottombar-back {
    0% {
      bottom   : 6px;
      transform: rotate(-135deg);
    }

    45% {
      transform: rotate(10deg);
    }

    75% {
      transform: rotate(-5deg);
    }

    100% {
      bottom   : 0px;
      transform: rotate(0);
    }
  }

  @-webkit-keyframes bottombar-back {
    0% {
      bottom           : 6px;
      -webkit-transform: rotate(-135deg);
    }

    45% {
      -webkit-transform: rotate(10deg);
    }

    75% {
      -webkit-transform: rotate(-5deg);
    }

    100% {
      bottom           : 0px;
      -webkit-transform: rotate(0);
    }
  }

  @-moz-keyframes bottombar-back {
    0% {
      bottom        : 6px;
      -moz-transform: rotate(-135deg);
    }

    45% {
      -moz-transform: rotate(10deg);
    }

    75% {
      -moz-transform: rotate(-5deg);
    }

    100% {
      bottom        : 0px;
      -moz-transform: rotate(0);
    }
  }

  .AdminMAinControl .navbar-toggler .icon-bar:nth-child(2) {
    top                        : 0px;
    -webkit-animation          : topbar-back 500ms linear 0s;
    -moz-animation             : topbar-back 500ms linear 0s;
    animation                  : topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode   : forwards;
    animation-fill-mode        : forwards;
  }

  .AdminMAinControl .navbar-toggler .icon-bar:nth-child(3) {
    opacity: 1;
  }

  .AdminMAinControl .navbar-toggler .icon-bar:nth-child(4) {
    bottom                     : 0px;
    -webkit-animation          : bottombar-back 500ms linear 0s;
    -moz-animation             : bottombar-back 500ms linear 0s;
    animation                  : bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode   : forwards;
    animation-fill-mode        : forwards;
  }

  .AdminMAinControl .navbar-toggler.toggled .icon-bar:nth-child(2) {
    top                        : 6px;
    -webkit-animation          : topbar-x 500ms linear 0s;
    -moz-animation             : topbar-x 500ms linear 0s;
    animation                  : topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode   : forwards;
    animation-fill-mode        : forwards;
  }

  .AdminMAinControl .navbar-toggler.toggled .icon-bar:nth-child(3) {
    opacity: 0;
  }

  .AdminMAinControl .navbar-toggler.toggled .icon-bar:nth-child(4) {
    bottom                     : 6px;
    -webkit-animation          : bottombar-x 500ms linear 0s;
    -moz-animation             : bottombar-x 500ms linear 0s;
    animation                  : bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode   : forwards;
    animation-fill-mode        : forwards;
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .AdminMAinControl .dropdown-menu .divider {
    background-color: rgba(229, 229, 229, 0.15);
  }

  .AdminMAinControl .navbar-nav {
    margin: 1px 0;
  }

  .AdminMAinControl .navbar-nav .open .dropdown-menu>li>a {
    padding: 15px 15px 5px 50px;
  }

  .AdminMAinControl .navbar-nav .open .dropdown-menu>li:first-child>a {
    padding: 5px 15px 5px 50px;
  }

  .AdminMAinControl .navbar-nav .open .dropdown-menu>li:last-child>a {
    padding: 15px 15px 25px 50px;
  }

  .AdminMAinControl [class*="navbar-"] .navbar-nav>li>a,
  .AdminMAinControl [class*="navbar-"] .navbar-nav>li>a:hover,
  .AdminMAinControl [class*="navbar-"] .navbar-nav>li>a:focus,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .active>a,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .active>a:hover,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .active>a:focus,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .open .dropdown-menu>li>a,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .open .dropdown-menu>li>a:hover,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .open .dropdown-menu>li>a:focus,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .navbar-nav .open .dropdown-menu>li>a:active {
    color: white;
  }

  .AdminMAinControl [class*="navbar-"] .navbar-nav>li>a,
  .AdminMAinControl [class*="navbar-"] .navbar-nav>li>a:hover,
  .AdminMAinControl [class*="navbar-"] .navbar-nav>li>a:focus,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .open .dropdown-menu>li>a,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .open .dropdown-menu>li>a:hover,
  .AdminMAinControl [class*="navbar-"] .navbar-nav .open .dropdown-menu>li>a:focus {
    opacity   : .7;
    background: transparent;
  }

  .AdminMAinControl [class*="navbar-"] .navbar-nav.navbar-nav .open .dropdown-menu>li>a:active {
    opacity: 1;
  }

  .AdminMAinControl [class*="navbar-"] .navbar-nav .dropdown>a:hover .caret {
    border-bottom-color: #777;
    border-top-color   : #777;
  }

  .AdminMAinControl [class*="navbar-"] .navbar-nav .dropdown>a:active .caret {
    border-bottom-color: white;
    border-top-color   : white;
  }

  .AdminMAinControl .dropdown-menu {
    display: none;
  }

  .AdminMAinControl .navbar-fixed-top {
    -webkit-backface-visibility: hidden;
  }

  .AdminMAinControl #bodyClick {
    height    : 100%;
    width     : 100%;
    position  : fixed;
    opacity   : 0;
    top       : 0;
    left      : auto;
    right     : 260px;
    content   : "";
    z-index   : 9999;
    overflow-x: hidden;
  }


  .AdminMAinControl .navbar-toggle:hover,
  .AdminMAinControl .navbar-toggle:focus {
    background-color: transparent !important;
  }


  .AdminMAinControl .navbar-collapse.collapse {
    height: 100% !important;
  }

  .AdminMAinControl .navbar-collapse.collapse.in {
    display: block;
  }

  .AdminMAinControl .navbar-header .collapse,
  .AdminMAinControl .navbar-toggle {
    display: block !important;
  }

  .AdminMAinControl .navbar-header {
    float: none;
  }

  .AdminMAinControl .navbar-collapse .nav p {
    font-size: 1rem;
    margin   : 0;
  }
}

@media (min-width: 992px) {
  .AdminMAinControl .main-panel .navbar .navbar-collapse .navbar-nav .nav-item .nav-link p {
    display: none;
  }

  .AdminMAinControl .nav-mobile-menu,
  .AdminMAinControl .sidebar .navbar-form {
    display: none !important;
  }


  .AdminMAinControl .sidebar.open {
    right: 260px;
  }


}

/* 
.AdminMAinControl .navbar-expand-lg .navbar-nav {
  flex-direction: row;
} */
.react-calendar {
  margin: auto;
}


.indexWeb {
  margin-top: 50px;
}